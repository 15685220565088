import { Doc } from "@/components/Doc/Doc";
import { FlashOnChangeTD } from "@/components/FlashOnChange/FlashOnChange";
import { LabelExplainer } from "@/components/LabelExplainer/LabelExplainer";
import {
  cashOnReturnAtom,
  cashOnReturnTotalAtom,
} from "@/financeModels/owasco/equity";
import { monospaceFontFamily } from "@/theme";
import { formatDecimalPercent } from "@/utils/format";
import { Table } from "@mantine/core";
import { useAtomValue } from "jotai";

export const CashOnReturnTable = () => {
  const cashOnReturn = useAtomValue(cashOnReturnAtom);
  const cashOnReturnTotal = useAtomValue(cashOnReturnTotalAtom);

  const items = [...cashOnReturn, cashOnReturnTotal];

  return (
    <Table striped withColumnBorders>
      <Table.Thead
        style={{
          position: "sticky",
          top: "0",
          background: "white",
        }}
      >
        <Table.Tr>
          <Table.Th
            ta={"center"}
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <LabelExplainer
              justify="center"
              lh={1.6}
              label={
                <>
                  Cash-On-Cash
                  <br />
                  Return
                </>
              }
              explanation={
                <Doc path={"@/docs/pages/model/roi/cash-on-cash-return.mdx"} />
              }
            />
          </Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {items.map((item, i) => (
          <Table.Tr key={i}>
            <FlashOnChangeTD
              content={item.toString()}
              ta={"right"}
              ff={monospaceFontFamily}
            >
              {formatDecimalPercent(item)}
            </FlashOnChangeTD>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
};
