import { DeferredRender } from "@/components/DeferedRender/DeferedRender";
import { Doc } from "@/components/Doc/Doc";
import { LabelExplainer } from "@/components/LabelExplainer/LabelExplainer";
import { manifest } from "@/docs/manifest";
import { CellTypes } from "@/financeModels/owasco/types";
import { isBetween } from "@/utils/math";
import { Badge, BoxComponentProps, Group, Stack } from "@mantine/core";
import { Atom, useAtomValue } from "jotai";
import { ReactNode, memo } from "react";
import { defaultHeight } from "../CellGrid/cell/Wrapper";

export const DSCRCell = memo(
  ({
    children,
    dscrAtom,
    docPath,
    ...props
  }: BoxComponentProps & {
    children: ReactNode;
    dscrAtom: Atom<CellTypes>;
    docPath: keyof typeof manifest;
  }) => {
    const dscr = useAtomValue(dscrAtom);

    const showDscr =
      dscr !== null &&
      dscr !== undefined &&
      Number(dscr.value) > 0 &&
      Number(dscr.value) < 99;

    const dscrLabel = !showDscr ? "---" : Number(dscr.value).toFixed(2) + "x";

    let dscrBgColor = "gray.6";

    if (isBetween(dscr.value, 0.01, 1.25)) {
      dscrBgColor = "red.5";
    }
    if (isBetween(dscr.value, 1.25, 1.5)) {
      dscrBgColor = "yellow.8";
    }

    if (isBetween(dscr.value, 1.5, 20)) {
      dscrBgColor = "green.5";
    }

    return (
      <Group
        gap={0}
        h={defaultHeight}
        justify="space-between"
        align="center"
        style={{
          backgroundColor: "white",
          backgroundImage: `linear-gradient(#aaa,#aaa)`,
          backgroundSize: "1px 30%",
          backgroundPosition: "right bottom",
          backgroundRepeat: "no-repeat",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 13,
        }}
        pr={4}
        pl={6}
        {...props}
      >
        <div>{children}</div>
        <DeferredRender>
          <Badge
            color={dscrBgColor}
            size="lg"
            tt={"none"}
            fz={12}
            h={30}
            style={{
              borderRadius: 13,
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 4,
              borderTopLeftRadius: 4,
            }}
          >
            <Stack gap={1} lh={1} justify="center" align="center">
              <LabelExplainer
                removeUnderline
                label={
                  <small
                    style={{ fontSize: 13, letterSpacing: 0.9, color: "white" }}
                  >
                    {dscrLabel}
                  </small>
                }
                explanation={<Doc path={docPath} />}
              />
              <LabelExplainer
                label={
                  <small
                    style={{ fontSize: 10, letterSpacing: 1, color: "white" }}
                  >
                    DSCR
                  </small>
                }
                explanation={<Doc path={docPath} />}
              />
            </Stack>
          </Badge>
        </DeferredRender>
      </Group>
    );
  },
);
