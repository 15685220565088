import { Doc } from "@/components/Doc/Doc";
import { FlashOnChangeTD } from "@/components/FlashOnChange/FlashOnChange";
import { LabelExplainer } from "@/components/LabelExplainer/LabelExplainer";
import { manifest } from "@/docs/manifest";
import {
  equityReturnsAllInvestorsAtom,
  equityReturnsAllInvestorsTotalsAtom,
  equityReturnsCommonEquityAtom,
  equityReturnsCommonEquityTotalsAtom,
  equityReturnsExternalInvestorsAtom,
  equityReturnsExternalInvestorsTotalsAtom,
  equityReturnsSearcherAtom,
  equityReturnsSearcherTotalsAtom,
  showSecretInvestorDistributionTablesCounterAtom,
} from "@/financeModels/owasco/equity";
import { monospaceFontFamily, textFontFamily } from "@/theme";
import { formatDollar } from "@/utils/format";
import { Stack, Table, Text } from "@mantine/core";
import { Atom, useAtomValue } from "jotai";
import { Fragment } from "react";

export const InvestorDistributionsTable = () => {
  const returns = useAtomValue(equityReturnsAllInvestorsAtom);
  const counter = useAtomValue(showSecretInvestorDistributionTablesCounterAtom);
  const totals = useAtomValue(equityReturnsAllInvestorsTotalsAtom);

  return (
    <>
      <InvestorTable returns={returns} totals={totals} />
      {counter > 5 && <HelperTables />}
    </>
  );
};

const HelperTables = () => {
  const table2 = useAtomValue(equityReturnsExternalInvestorsAtom);
  const table3a = useAtomValue(equityReturnsSearcherAtom);
  const table3b4 = useAtomValue(equityReturnsCommonEquityAtom);

  const table2Totals = useAtomValue(equityReturnsExternalInvestorsTotalsAtom);
  const table3aTotals = useAtomValue(equityReturnsSearcherTotalsAtom);
  const table3b4Totals = useAtomValue(equityReturnsCommonEquityTotalsAtom);

  return (
    <Stack>
      <Text>Table 2</Text>
      <InvestorTable returns={table2 as never} totals={table2Totals} />

      <Text>table 3a</Text>
      <InvestorTable returns={table3a as never} totals={table3aTotals} />

      <Text>table 3b and 4</Text>
      <InvestorTable
        returns={table3b4 as never}
        totals={table3b4Totals as never}
      />
    </Stack>
  );
};

type TableValue = typeof equityReturnsAllInvestorsAtom extends Atom<infer T>
  ? T
  : never;

type TableTotalsValue = typeof equityReturnsAllInvestorsTotalsAtom extends Atom<
  infer T
>
  ? T
  : never;

const InvestorTable = ({
  returns,
  totals,
}: {
  returns: TableValue;
  totals: TableTotalsValue;
}) => {
  const cols: {
    title: string;
    field: keyof TableValue[0];
    docPath?: keyof typeof manifest;
  }[] = [
    {
      title: "Period",
      field: "period",
      docPath: "@/docs/pages/model/roi/id-period.mdx",
    },
    {
      title: "Year",
      field: "year",
      docPath: "@/docs/pages/model/roi/id-year.mdx",
    },
    {
      title: "Preferred\nDividends",
      field: "preferredDividends",
      docPath: "@/docs/pages/model/roi/id-preferred-divdends.mdx",
    },
    {
      title: "Principal\nRepayments",
      field: "principalRepayments",
      docPath: "@/docs/pages/model/roi/id-principal-repayments.mdx",
    },
    {
      title: "Profit\nDistributions",
      field: "profitDistributions",
      docPath: "@/docs/pages/model/roi/id-profit-distributions.mdx",
    },
    {
      title: "Tax\nDistributions",
      field: "taxDistributions",
      docPath: "@/docs/pages/model/roi/id-tax-distributions.mdx",
    },
    {
      title: "Net Sale\nProceeds",
      field: "proceedsFromCompanySale",
      docPath: "@/docs/pages/model/roi/id-sale-proceeds.mdx",
    },
    {
      title: "Total Investor\nDistributions",
      field: "totalEquityInvestorDistributions",
      docPath: "@/docs/pages/model/roi/id-total-investor-distrubutions.mdx",
    },
  ] as const;

  const items = [
    ...returns,
    {
      period: "Total",
      year: "",
      ...totals,
    },
  ] as {
    year: number;
    period: number;
    beginningBalance: number;
    endingBalance: number;
    preferredDividends: number;
    principalRepayments: number;
    profitDistributions: number;
    proceedsFromCompanySale: number;
    taxDistributions: number;
    totalEquityInvestorDistributions: number;
    principalHasBeenRepaid: boolean;
  }[];

  return (
    <Table striped withColumnBorders flex={1}>
      <Table.Thead
        style={{
          position: "sticky",
          top: "0",
          background: "white",
        }}
      >
        <Table.Tr>
          {cols.map((col) => (
            <Table.Th
              ta={"center"}
              key={col.field}
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              <LabelExplainer
                lh={1.6}
                justify="center"
                label={col.title}
                explanation={col.docPath ? <Doc path={col.docPath} /> : null}
              />
            </Table.Th>
          ))}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody ff={monospaceFontFamily}>
        {items.map((item, i) => (
          <Table.Tr
            key={item.period}
            bg={i === items.length - 1 ? "infomatic.0" : ""}
            fw={i === items.length - 1 ? "bold" : ""}
          >
            {cols.map((col) => (
              <Fragment key={col.field}>
                {["period", "year"].includes(col.field) ? (
                  <Table.Td
                    key={col.field}
                    ta={col.field === "period" ? "center" : "right"}
                    ff={
                      col.field === "period" && i === items.length - 1
                        ? textFontFamily
                        : ""
                    }
                  >
                    {col.field === "year" ? item.year : item.period}
                  </Table.Td>
                ) : (
                  <FlashOnChangeTD
                    content={item[col.field]?.toString()}
                    key={col.field}
                    ta={"right"}
                  >
                    {item[col.field] === 0
                      ? "$0.00"
                      : formatDollar(item[col.field] as number)}
                  </FlashOnChangeTD>
                )}
              </Fragment>
            ))}
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
};
