import { Table, TableTdProps, TableTrProps } from "@mantine/core";
import { ReactNode, memo, useEffect, useState } from "react";

const useFlashOnChange = (
  content: string | number | boolean | null | undefined,
) => {
  const [animationKey, setAnimationKey] = useState<number>(-3);

  useEffect(() => {
    if (animationKey === undefined) return;
    setAnimationKey((c) => (c >= 1 ? 0 : c + 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const className = `flash-${animationKey}`;

  return className;
};

export const FlashOnChangeTD = memo(
  ({
    content,
    children,
    ...props
  }: {
    children?: ReactNode;
    content: string | number | boolean | null | undefined;
  } & TableTdProps) => {
    const className = useFlashOnChange(content);

    return (
      <Table.Td className={className} {...props}>
        {children}
      </Table.Td>
    );
  },
);

export const FlashOnChangeTR = memo(
  ({
    content,
    children,
    ...props
  }: {
    children?: ReactNode;
    content: string | number | boolean | null | undefined;
  } & TableTrProps) => {
    const className = useFlashOnChange(content);

    return (
      <Table.Tr className={className} {...props}>
        {children}
      </Table.Tr>
    );
  },
);
