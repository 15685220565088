import { LayoutFullscreen } from "@/components/LayoutFullscreen/LayoutFullscreen";
import { useNewsletterSignup } from "@/hooks/useNewsletterSignup";
import {
  Button,
  Container,
  Paper,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";

export const PageWaitlist = () => {
  const [signup, loading, , completed] = useNewsletterSignup();
  const form = useForm({
    initialValues: {
      email: "",
    },
  });

  const handleSubmit = async () => {
    signup(form.values.email);
    //await signInWithEmailAndPassword(form.values.email, form.values.password);
  };

  return (
    <LayoutFullscreen>
      <Container size={420} my={40} pb={"xl"}>
        <Text ta={"center"} fz={40}>
          Join the waitlist
        </Text>
        <Text ta={"center"} c="dimmed" size="md" mt={5}>
          Want to get in early? Share your email for a chance to access our
          Closed Beta and stay updated!
        </Text>

        <Paper withBorder shadow="md" p={30} mt={30}>
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack gap={0}>
              {!completed && (
                <>
                  <TextInput
                    size="md"
                    label="Email"
                    type="email"
                    placeholder="hello@example.com"
                    required
                    value={form.values.email}
                    onChange={(event) =>
                      form.setFieldValue("email", event.currentTarget.value)
                    }
                  />
                  <Button
                    size="md"
                    fullWidth
                    mt="xl"
                    loading={loading}
                    type="submit"
                  >
                    Sign up for waitlist
                  </Button>
                </>
              )}

              {completed && (
                <Text c="green" mt="xl" ta="center">
                  Thanks for signing up! <br /> We'll keep you updated.
                </Text>
              )}
            </Stack>
          </form>
        </Paper>
      </Container>
    </LayoutFullscreen>
  );
};
