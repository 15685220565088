import {
  Badge,
  Container,
  Group,
  Paper,
  SimpleGrid,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import {
  TbLayoutDashboard,
  TbMailHeart,
  TbPresentationAnalytics,
  TbReportSearch,
} from "react-icons/tb";

const features = [
  {
    title: "Financial Modeling",
    description:
      "Start with basics, build up to a full cash flow model for lenders & investors.",
    cta: "See an example",
    icon: TbPresentationAnalytics,
    //linkTo: "/cash-flow-model",
  },
  {
    title: "Deal Management",
    description:
      "Manage your prospective deals in a centralized dashboard. Identify trends with ease.",
    cta: "Deal Tracker",
    icon: TbLayoutDashboard,
  },
  {
    title: "IOIs & LOIs",
    description: "Generate tailored offer letters quickly.",
    cta: "IOI & LOI Generator",
    icon: TbMailHeart,
  },
  {
    title: "Deal Analysis",
    description:
      "In-product reports and education for understanding the viability of a deal.",
    cta: "Deal Metrics",
    icon: TbReportSearch,
  },
];

export function FeaturesTitle() {
  return (
    <section
      style={{
        backgroundColor: "#F9F5F1",
      }}
    >
      <Container size="lg">
        <SimpleGrid cols={{ base: 2, md: 4 }}>
          {features.map((feature) => {
            return (
              <Paper
                withBorder
                key={feature.title}
                p={"lg"}
                pos={"relative"}
                top={-20}
                display={"flex"}
                style={{
                  flexDirection: "column",
                }}
                radius={0}
              >
                <ThemeIcon
                  size={50}
                  variant="white"
                  radius={999}
                  mb={"lg"}
                  color="accent"
                >
                  <feature.icon size={"10em"} />
                </ThemeIcon>
                <Title order={3} fw={"bold"} mb={"md"}>
                  {feature.title}
                </Title>

                <Text mb={"md"} c="dimmed">
                  {feature.description}
                </Text>
                <Group align="center" mt={"auto"}>
                  {/* {feature.linkTo && (
                    <>
                      <FaPlayCircle size={24} />
                      <Anchor
                        variant="transparent"
                        underline={feature.linkTo ? "always" : "never"}
                        size="lg"
                      >
                        {feature.cta}
                      </Anchor>
                    </>
                  )}
                  {!feature.linkTo && <Badge size="lg">Coming Soon</Badge>} */}

                  <Badge size="lg">Coming Soon</Badge>
                </Group>
              </Paper>
            );
          })}
        </SimpleGrid>
      </Container>
    </section>
  );
}
