import { textFontFamily } from "@/theme";
import { Group, Select, SelectProps, Text } from "@mantine/core";
import { SidebarInputProps } from "./types";

export const InputSelect = ({
  labelWidth = 181,
  label,
  ...props
}: SidebarInputProps & SelectProps) => {
  return (
    <Group gap={0} justify="space-between">
      <Text
        style={{
          fontWeight: 500,
          fontSize: 14,
        }}
        size={"md"}
        w={labelWidth}
        mr={"auto"}
        component="div"
      >
        {label}
      </Text>

      <Select
        ff={textFontFamily}
        withCheckIcon={false}
        size="sm"
        {...props}
        w={300 - labelWidth}
        styles={{
          wrapper: { fontFamily: textFontFamily },
        }}
      />
    </Group>
  );
};
