import { createItemizationAtom } from "@/financeModels/owasco/itemization";
import { CellTypes } from "@/financeModels/owasco/types";
import {
  ActionIcon,
  Box,
  Button,
  Collapse,
  Group,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { Atom, PrimitiveAtom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { ReactNode, useRef } from "react";
import { MdOutlinePlaylistRemove } from "react-icons/md";
import { RiFileList3Fill } from "react-icons/ri";
import { RxTriangleDown, RxTriangleRight } from "react-icons/rx";
import { AutocompleteCell, autoCompleteClassName } from "./AutocompleteCell";
import { CellRow } from "./CellRow";
import { defaultLabelColumnWidth } from "./LabelColumn";
import { LineItemLabel } from "./LineItemLabel";
import { lineSize } from "./Waterfall";
import { getFocusableElement } from "./hooks/useTabCoordinates";

export const Itemization = ({
  itemizationAtom,
  label,
  tabX = 0,
  tabY,
}: {
  itemizationAtom: ReturnType<typeof createItemizationAtom>;
  label: ReactNode;
  tabX?: number;
  tabY: number;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const itemization = useAtomValue(itemizationAtom);
  const [isActive, setIsActive] = useAtom(itemization.activeAtom);
  const [isOpen, setIsOpen] = useAtom(itemization.openAtom);
  const createLineItem = useSetAtom(itemization.createLineItemAtom);
  const lineItems = itemization.lineItems;

  const openRevertLineItemsModal = async () => {
    await modals.openConfirmModal({
      title: "Are you sure you want to revert to a single entry?",

      children: (
        <Text size="md">
          Switching to a single entry will remove all itemized rows and restore
          the total value to the original single-line amount you entered.
          <br />
          <br />
          Don’t worry – we’ll save all your itemized entries. If you decide to
          go back to itemization later, everything you entered will still be
          there.
        </Text>
      ),
      labels: {
        confirm: `Revert to Single Entry`,
        cancel: "Cancel",
      },
      confirmProps: { color: "green" },
      onConfirm: async () => {
        setIsOpen(true);
        setIsActive(false);
      },
    });
  };

  return (
    <Stack gap={0} ref={ref} className="itemization">
      <CellRow
        style={
          {
            // borderBottom: isActive ? "1px solid rgba(0, 0, 0, 0.1)" : "none",
          }
        }
        tabX={tabX}
        tabY={tabY}
        molecule={
          itemization.summarizedMolecule as Atom<PrimitiveAtom<CellTypes>[]>
        }
        label={
          <Group gap={3} justify="space-between">
            <div>{label}</div>
            {isActive && (
              <Button
                size="compact-xs"
                variant="light"
                color="accent"
                rightSection={
                  isOpen ? (
                    <RxTriangleDown size={16} />
                  ) : (
                    <RxTriangleRight size={16} />
                  )
                }
                onClick={() => {
                  setIsOpen((o) => !o);
                }}
                maw={80}
                tt={"lowercase"}
                styles={{
                  section: {
                    marginInlineStart: 0,
                  },
                }}
              >
                {lineItems.length} item{lineItems.length === 1 ? "" : "s"}
              </Button>
            )}
            {!isActive && (
              <Tooltip label="Itemize Expenses">
                <ActionIcon
                  size={"sm"}
                  aria-label="Itemize List"
                  radius={2}
                  onClick={() => {
                    setIsActive((a) => !a);
                    setTimeout(() => {
                      if (!ref || !ref.current) return;

                      const ele = ref.current.querySelectorAll(
                        `.${autoCompleteClassName}`,
                      )[0] as HTMLElement;

                      getFocusableElement(ele)?.focus();
                    }, 200);
                  }}
                  tabIndex={999999}
                  ml="auto"
                  variant="light"
                  color="accent.4"
                >
                  <RiFileList3Fill />
                </ActionIcon>
              </Tooltip>
            )}
          </Group>
        }
      />
      {isActive && (
        <Collapse in={isOpen}>
          <Box
            style={{
              boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset",
              backgroundColor: "var(--mantine-color-gray-1)",
            }}
            pos="relative"
          >
            {(lineItems || []).map((lineItem, index) => (
              <CellRow
                backdropColor={"var(--mantine-color-gray-1)"}
                bg="white"
                labelWidth={defaultLabelColumnWidth - 30}
                molecule={itemization.lineItemsMolecules[index]}
                key={index}
                label={
                  <LineItemLabel
                    lineItem={lineItem}
                    itemizationAtom={itemizationAtom}
                  />
                }
                tabY={tabY + (index + 1) * 0.001}
              />
            ))}
            <Group>
              <Group pos={"sticky"} left={lineSize} pl={60}>
                {isOpen && (
                  <AutocompleteCell
                    tabY={tabY + 0.999}
                    tabX={0}
                    isFullWidth
                    onSubmit={(label) => createLineItem({ label })}
                  />
                )}
              </Group>
              <Group pos={"sticky"} left={"calc(100% - 200px)"}>
                <Button
                  size={"compact-xs"}
                  aria-label="Itemize List"
                  onClick={openRevertLineItemsModal}
                  tabIndex={999999}
                  leftSection={<MdOutlinePlaylistRemove size={18} />}
                  variant="light"
                  color="accent"
                  mr={6}
                >
                  Revert to Single Line Item
                </Button>
              </Group>
            </Group>
          </Box>
        </Collapse>
      )}
    </Stack>
  );
};
