import { Doc } from "@/components/Doc/Doc";
import { LabelExplainer } from "@/components/LabelExplainer/LabelExplainer";
import { cloneFromEpochsAtom } from "@/financeModels/owasco/clone";
import { isProjectionDirtyAtom } from "@/financeModels/owasco/projections";
import {
  Button,
  Center,
  Group,
  Paper,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { useSetAtom } from "jotai";
import { FaStar } from "react-icons/fa";

export const Start = () => {
  const setDirty = useSetAtom(isProjectionDirtyAtom);
  const clone = useSetAtom(cloneFromEpochsAtom);

  const BaseYearExplainer = () => {
    return (
      <LabelExplainer
        inline
        label="Base Year"
        explanation={
          <Doc path="@/docs/pages/model/projections/base-year.mdx" />
        }
      />
    );
  };

  return (
    <Center h={"100%"} w={"100%"}>
      <div style={{ display: "none" }}>
        <Doc path="@/docs/pages/model/projections/base-year.mdx" />
        Preload no blink on render
      </div>
      <Stack justify="center" align="center" pb="xl">
        <div
          style={{
            height: 0,
            position: "relative",
            top: -100,
            textAlign: "center",
          }}
        >
          <Text size="xl">Ready to Start Your Forward Projections?</Text>
          <Text mb={40}>
            Choose a starting point for the Base Year of your projections:
          </Text>
        </div>
        <Group align="space-between" justify="center">
          <Tooltip
            zIndex={1}
            withinPortal={false}
            inline
            label={
              <Group justify="center" align="center" gap={4}>
                <FaStar style={{ marginBottom: 3 }} />
                <Text size="sm" fw="bold">
                  We Recommend
                </Text>
              </Group>
            }
            opened
            offset={-10}
            w={300}
            radius={0}
            withArrow
            arrowSize={8}
            arrowOffset={40}
            bg={"green.7"}
            ta={"center"}
            fw={"bold"}
            position="top-end"
            visibleFrom="xs"
          >
            <Paper withBorder p={"xl"} w={300}>
              <Stack>
                <Text>
                  Jumpstart your projections by importing the most recent year
                  of your Historicals to establish your <BaseYearExplainer />
                </Text>
                <Button
                  onClick={() => {
                    clone();
                    setDirty(true);
                  }}
                >
                  Build from Historical Tab
                </Button>
              </Stack>
            </Paper>
          </Tooltip>

          <Paper withBorder p={"xl"} w={300}>
            <Stack h={"100%"} justify="space-between">
              <Text>
                Start with a blank template to enter the financials for your{" "}
                <BaseYearExplainer />
              </Text>
              <Button onClick={() => setDirty(true)}>
                Start a blank template
              </Button>
            </Stack>
          </Paper>
        </Group>
      </Stack>
    </Center>
  );
};
