import { Doc } from "@/components/Doc/Doc";
import { LabelExplainer } from "@/components/LabelExplainer/LabelExplainer";
import {
  commonEquitySearcherPercentAtom,
  preferredEquityInvestorPercentAtom,
  preferredEquitySearcherPercentAtom,
  totalEquityDollarAtom,
} from "@/financeModels/owasco/equity";
import {
  equityInputDollarAtom,
  equityInvestorEquityStepUpAtom,
  equityInvestorInputDollarAtom,
  equityInvestorPercentAtom,
  equityInvestorPreferredEquityRateAtom,
  equityInvestorStartPreferredDividendsAtom,
  equityInvestorStartPrincipalRepaymentsAtom,
  equityPercentAtom,
  financeStructureErrorMessageAtom,
  financeStructureIsDollarFormatAtom,
  isEquityInvestorsIncludedAtom,
  isFinanceStructureInErrorAtom,
  sbaLoanInputDollarAtom,
  sbaLoanPercentAtom,
  sellersNoteInputDollarAtom,
  sellersNotePercentAtom,
  targetPurchasePriceAtom,
} from "@/financeModels/owasco/general";
import {
  formatDecimalPercentTwoPlaces,
  formatDollar,
  numberToPercentInput,
  percentInputToNumber,
} from "@/utils/format";
import { lazyReactFallback } from "@/utils/lazy";
import { toNumberOrNull } from "@/utils/math";
import { Alert, Divider, Stack, Text } from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { ChangeEvent } from "react";
import { TbLayoutBoardSplit } from "react-icons/tb";
import { ComputedResultContainer, ComputedResultLine } from "./ComputedResult";
import { InputDollar } from "./InputDollar";
import { InputDollarPercentSwitch } from "./InputDollarPercentSwitch";
import { InputPercent } from "./InputPercent";
import { InputSelect } from "./InputSelect";
import { InputToggle } from "./InputToggle";

const FinanceStructureSB7aExplainer = lazyReactFallback(
  () => import("@/docs/pages/model/sidebar/financeStructureSB7a.mdx"),
);
const FinanceStructureSellersNoteExplainer = lazyReactFallback(
  () => import("@/docs/pages/model/sidebar/financeStructureSellersNote.mdx"),
);

const FinanceStructureEquityExplainer = lazyReactFallback(
  () => import("@/docs/pages/model/sidebar/financeStructureEquity.mdx"),
);

const FinanceStructureHasEquityInvestorExplainer = lazyReactFallback(
  () =>
    import("@/docs/pages/model/sidebar/financeStructureHasEquityInvestor.mdx"),
);

const FinanceStructureEquityInvestorExplainer = lazyReactFallback(
  () => import("@/docs/pages/model/sidebar/financeStructureEquityInvestor.mdx"),
);

const FinanceStructureEquityInvestorPreferredDividendsExplainer =
  lazyReactFallback(
    () =>
      import(
        "@/docs/pages/model/sidebar/financeStructureEquityInvestorPreferredDividends.mdx"
      ),
  );

const FinanceStructureEquityInvestorPreferredRateExplainer = lazyReactFallback(
  () =>
    import(
      "@/docs/pages/model/sidebar/financeStructureEquityInvestorPreferredRate.mdx"
    ),
);

const FinanceStructureEquityInvestorRepaymentsExplainer = lazyReactFallback(
  () =>
    import(
      "@/docs/pages/model/sidebar/financeStructureEquityInvestorRepayments.mdx"
    ),
);

const FinanceStructureEquityInvestorStepUpExplainer = lazyReactFallback(
  () =>
    import(
      "@/docs/pages/model/sidebar/financeStructureEquityInvestorStepUp.mdx"
    ),
);

const Panel = () => {
  const [isInvestorIncluded, setInvestorIncluded] = useAtom(
    isEquityInvestorsIncludedAtom,
  );
  const [isDollarFormat, setIsDollarFormat] = useAtom(
    financeStructureIsDollarFormatAtom,
  );

  const [sbaLoanPercent, setSbaLoanPercent] = useAtom(sbaLoanPercentAtom);
  const [sellersNotePercent, setSellersNotePercent] = useAtom(
    sellersNotePercentAtom,
  );
  const [equityPercent, setEquityPercent] = useAtom(equityPercentAtom);
  const [equityInvestorPercent, setEquityInvestorPercent] = useAtom(
    equityInvestorPercentAtom,
  );

  const [sbaLoanDollar, setSbaLoanDollar] = useAtom(sbaLoanInputDollarAtom);
  const [sellersNoteDollar, setSellersNoteDollar] = useAtom(
    sellersNoteInputDollarAtom,
  );
  const [equityDollar, setEquityDollar] = useAtom(equityInputDollarAtom);
  const [equityInvestorDollar, setEquityInvestorDollar] = useAtom(
    equityInvestorInputDollarAtom,
  );
  const purchasePrice = useAtomValue(targetPurchasePriceAtom);

  const financeStructureErrorMessage = useAtomValue(
    financeStructureErrorMessageAtom,
  );

  const [equityInvestorInterestRate, setEquityInvestorInterestRate] = useAtom(
    equityInvestorPreferredEquityRateAtom,
  );

  const [equityInvestorStepUp, setEquityInvestorStepUp] = useAtom(
    equityInvestorEquityStepUpAtom,
  );

  const [
    equityInvestorStartPrincipalRepayments,
    setEquityInvestorStartPrincipalRepayments,
  ] = useAtom(equityInvestorStartPrincipalRepaymentsAtom);

  const [
    equityInvestorStartPreferredDividends,
    setEquityInvestorStartPreferredDividends,
  ] = useAtom(equityInvestorStartPreferredDividendsAtom);

  const totalEquityDollar = useAtomValue(totalEquityDollarAtom);
  const preferredEquitySearcherPercent = useAtomValue(
    preferredEquitySearcherPercentAtom,
  );
  const preferredEquityInvestorPercent = useAtomValue(
    preferredEquityInvestorPercentAtom,
  );
  const commonEquitySearcherPercent = useAtomValue(
    commonEquitySearcherPercentAtom,
  );

  const onChangeFormat = (value: string) => {
    setIsDollarFormat(value === "dollar");
    if (value === "dollar") {
      setSbaLoanDollar(
        Number(sbaLoanPercent) * Number(purchasePrice) || 0,
        true,
      );
      setSellersNoteDollar(
        Number(sellersNotePercent) * Number(purchasePrice) || 0,
        true,
      );
      setEquityDollar(Number(equityPercent) * Number(purchasePrice) || 0, true);
      setEquityInvestorDollar(
        Number(equityInvestorPercent) * Number(purchasePrice) || 0,
        true,
      );
    } else {
      setSbaLoanPercent(Number(sbaLoanDollar) / Number(purchasePrice), true);
      setSellersNotePercent(
        Number(sellersNoteDollar) / Number(purchasePrice),
        true,
      );
      setEquityPercent(Number(equityDollar) / Number(purchasePrice), true);
      setEquityInvestorPercent(
        Number(equityInvestorDollar) / Number(purchasePrice),
        true,
      );
    }
  };

  const ToggleInvestorIncluded = (
    <InputToggle
      label={
        <LabelExplainer
          label="Do you have equity investors?"
          explanation={<FinanceStructureHasEquityInvestorExplainer />}
        />
      }
      checked={isInvestorIncluded}
      onChange={(event) => {
        setInvestorIncluded(
          (event as ChangeEvent<HTMLInputElement>).currentTarget.checked,
        );
      }}
    />
  );

  return (
    <Stack gap={4}>
      <InputDollarPercentSwitch
        value={isDollarFormat}
        onChange={onChangeFormat}
      />

      {!isDollarFormat && (
        <>
          <InputPercent
            label={
              <LabelExplainer
                label="SBA 7(a) Loan"
                explanation={<FinanceStructureSB7aExplainer />}
              />
            }
            value={numberToPercentInput(sbaLoanPercent)}
            onChange={(value) => setSbaLoanPercent(percentInputToNumber(value))}
          />

          <InputPercent
            label={
              <LabelExplainer
                label="Seller's Note"
                explanation={<FinanceStructureSellersNoteExplainer />}
              />
            }
            value={numberToPercentInput(sellersNotePercent)}
            onChange={(value) =>
              setSellersNotePercent(percentInputToNumber(value))
            }
          />
          {ToggleInvestorIncluded}
          <InputPercent
            label={
              <LabelExplainer
                label={isInvestorIncluded ? "Equity - Searcher" : "Equity"}
                explanation={<FinanceStructureEquityExplainer />}
              />
            }
            value={numberToPercentInput(equityPercent)}
            onChange={(value) => setEquityPercent(percentInputToNumber(value))}
          />
          {isInvestorIncluded && (
            <InputPercent
              label={
                <LabelExplainer
                  label={"Equity - Investor"}
                  explanation={<FinanceStructureEquityExplainer />}
                />
              }
              value={numberToPercentInput(equityInvestorPercent)}
              onChange={(value) =>
                setEquityInvestorPercent(percentInputToNumber(value))
              }
            />
          )}
        </>
      )}

      {isDollarFormat && (
        <>
          <InputDollar
            label={
              <LabelExplainer
                label="SBA 7(a) Loan"
                explanation={<FinanceStructureSB7aExplainer />}
              />
            }
            value={Number(sbaLoanDollar)}
            onChange={(value) => setSbaLoanDollar(toNumberOrNull(value))}
          />

          <InputDollar
            label={
              <LabelExplainer
                label="Seller's Note"
                explanation={<FinanceStructureSellersNoteExplainer />}
              />
            }
            value={Number(sellersNoteDollar)}
            onChange={(value) => setSellersNoteDollar(toNumberOrNull(value))}
          />
          {ToggleInvestorIncluded}
          <InputDollar
            label={
              <LabelExplainer
                label={isInvestorIncluded ? "Equity - Searcher" : "Equity"}
                explanation={<FinanceStructureEquityExplainer />}
              />
            }
            value={Number(equityDollar)}
            onChange={(value) => setEquityDollar(toNumberOrNull(value))}
          />

          {isInvestorIncluded && (
            <InputDollar
              label={
                <LabelExplainer
                  label={"Equity - Investor"}
                  explanation={<FinanceStructureEquityInvestorExplainer />}
                />
              }
              value={Number(equityInvestorDollar)}
              onChange={(value) =>
                setEquityInvestorDollar(toNumberOrNull(value))
              }
            />
          )}
        </>
      )}

      {financeStructureErrorMessage && (
        <Alert variant="light" color="red" ta={"center"}>
          {financeStructureErrorMessage}
        </Alert>
      )}
      {isInvestorIncluded && (
        <>
          <Divider my="sm" />
          <Text size="sm" fw={"bold"} mb="sm">
            Equity Investor Terms
          </Text>

          <InputPercent
            label={
              <LabelExplainer
                label="Preferred Equity Rate"
                explanation={
                  <FinanceStructureEquityInvestorPreferredRateExplainer />
                }
              />
            }
            value={numberToPercentInput(equityInvestorInterestRate)}
            onChange={(value) =>
              setEquityInvestorInterestRate(percentInputToNumber(value))
            }
          />

          <InputPercent
            rightSection="x"
            label={
              <LabelExplainer
                label="Equity Step-Up"
                explanation={<FinanceStructureEquityInvestorStepUpExplainer />}
              />
            }
            value={toNumberOrNull(equityInvestorStepUp) || ""}
            onChange={(value) => setEquityInvestorStepUp(toNumberOrNull(value))}
          />

          <InputSelect
            labelWidth={201}
            data={yearSelectData}
            label={
              <LabelExplainer
                label="Preferred Dividends Start"
                explanation={
                  <FinanceStructureEquityInvestorPreferredDividendsExplainer />
                }
              />
            }
            value={`${
              toNumberOrNull(equityInvestorStartPreferredDividends) || ""
            }`}
            onChange={(value) =>
              setEquityInvestorStartPreferredDividends(
                toNumberOrNull(Number(value)),
              )
            }
          />

          <InputSelect
            labelWidth={201}
            data={yearSelectData}
            label={
              <LabelExplainer
                label="Principal Repayments Start"
                explanation={
                  <FinanceStructureEquityInvestorRepaymentsExplainer />
                }
              />
            }
            value={`${
              toNumberOrNull(equityInvestorStartPrincipalRepayments) || ""
            }`}
            onChange={(value) =>
              setEquityInvestorStartPrincipalRepayments(
                toNumberOrNull(Number(value)),
              )
            }
          />

          <ComputedResultContainer>
            <Text size="sm" c="dimmed" mb={"xs"} fw={"bold"}>
              <LabelExplainer
                label="Company Ownership (Post Step-Up)"
                explanation={
                  <Doc path="@/docs/pages/model/sidebar/financeStructureCompanyOwnership.mdx" />
                }
              />
            </Text>
            <ComputedResultLine
              label={
                <LabelExplainer
                  label="Total Equity"
                  explanation={
                    <Doc path="@/docs/pages/model/sidebar/financeStructureTotalEquity.mdx" />
                  }
                />
              }
              value={
                toNumberOrNull(totalEquityDollar)
                  ? formatDollar(Number(totalEquityDollar))
                  : null
              }
            />
            <ComputedResultLine
              label={
                <LabelExplainer
                  label="Searcher - Common Equity"
                  explanation={
                    <Doc path="@/docs/pages/model/sidebar/financeStructureSearcherCommonEquity.mdx" />
                  }
                />
              }
              value={
                toNumberOrNull(commonEquitySearcherPercent)
                  ? formatDecimalPercentTwoPlaces(commonEquitySearcherPercent)
                  : ""
              }
            />
            <ComputedResultLine
              label={
                <LabelExplainer
                  label="Searcher - Preferred Equity"
                  explanation={
                    <Doc path="@/docs/pages/model/sidebar/financeStructureSearcherPreferredEquity.mdx" />
                  }
                />
              }
              value={
                toNumberOrNull(preferredEquitySearcherPercent)
                  ? formatDecimalPercentTwoPlaces(
                      preferredEquitySearcherPercent,
                    )
                  : ""
              }
            />
            <ComputedResultLine
              label={
                <LabelExplainer
                  label="Investors - Preferred Equity"
                  explanation={
                    <Doc path="@/docs/pages/model/sidebar/financeStructureInvestorsPreferredEquity.mdx" />
                  }
                />
              }
              value={
                toNumberOrNull(preferredEquityInvestorPercent)
                  ? formatDecimalPercentTwoPlaces(
                      preferredEquityInvestorPercent,
                    )
                  : ""
              }
            />
          </ComputedResultContainer>
        </>
      )}
    </Stack>
  );
};

export const ModuleFinanceStructure = {
  key: "Finance Structure",
  icon: <TbLayoutBoardSplit />,
  control: "Finance Structure",
  panel: <Panel />,
  errorAtom: isFinanceStructureInErrorAtom,
};

const yearSelectData = [
  { value: "1", label: "Year 1" },
  { value: "2", label: "Year 2" },
  { value: "3", label: "Year 3" },
  { value: "4", label: "Year 4" },
  { value: "5", label: "Year 5" },
  { value: "6", label: "Year 6" },
  { value: "7", label: "Year 7" },
  { value: "8", label: "Year 8" },
  { value: "9", label: "Year 9" },
  { value: "10", label: "Year 10" },
];
