import { Box, Text } from "@mantine/core";

export const ViewportSizeName = () => {
  return (
    <Box
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        display:
          import.meta.env.DEV && window.location.href.includes("localhost")
            ? "block"
            : "none",
      }}
    >
      <Text
        px={4}
        fz="xs"
        bg={"red"}
        display={{
          base: "none",
          xs: "block",
          sm: "none",
          md: "none",
          lg: "none",
          xl: "none",
        }}
      >
        xs
      </Text>
      <Text
        px={4}
        fz="xs"
        bg={"white"}
        display={{
          base: "none",
          xs: "none",
          sm: "block",
          md: "none",
          lg: "none",
          xl: "none",
        }}
      >
        sm
      </Text>
      <Text
        px={4}
        fz="xs"
        bg={"blue"}
        display={{
          base: "none",
          xs: "none",
          sm: "none",
          md: "block",
          lg: "none",
          xl: "none",
        }}
      >
        md
      </Text>
      <Text
        px={4}
        fz="xs"
        bg={"green"}
        display={{
          base: "none",
          xs: "none",
          sm: "none",
          md: "none",
          lg: "block",
          xl: "none",
        }}
      >
        lg
      </Text>
      <Text
        px={4}
        fz="xs"
        bg={"yellow"}
        display={{
          base: "none",
          xs: "none",
          sm: "none",
          md: "none",
          lg: "none",
          xl: "block",
        }}
      >
        xl
      </Text>
    </Box>
  );
};
