import { Doc } from "@/components/Doc/Doc";
import { LabelExplainer } from "@/components/LabelExplainer/LabelExplainer";
import {
  buyerCompBuyersSalaryAtom,
  buyerCompHealthBenefitsAtom,
  buyerCompPayrollTaxesAtom,
  buyerCompTotalAtom,
} from "@/financeModels/owasco/general";
import { formatDollar } from "@/utils/format";
import { lazyReactFallback } from "@/utils/lazy";
import { toNumberOrNull } from "@/utils/math";
import { Stack } from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { TbCoins } from "react-icons/tb";
import { ComputedResult } from "./ComputedResult";
import { InputDollar } from "./InputDollar";

const BuyersCompAnnualBaseSallaryExplainer = lazyReactFallback(
  () => import("@/docs/pages/model/sidebar/buyersCompAnnualBaseSallary.mdx"),
);
const BuyersCompPayrollTaxesExplainer = lazyReactFallback(
  () => import("@/docs/pages/model/sidebar/buyersCompPayrollTaxes.mdx"),
);
const BuyersCompHealthBenefitsExplainer = lazyReactFallback(
  () => import("@/docs/pages/model/sidebar/buyersCompHealthBenefits.mdx"),
);

const Panel = () => {
  const [buyerSalary, setBuyerSalary] = useAtom(buyerCompBuyersSalaryAtom);
  const [buyerPayrollTaxes, setBuyerPayrollTaxes] = useAtom(
    buyerCompPayrollTaxesAtom,
  );
  const [buyerHealthBenefits, setBuyerHealthBenefits] = useAtom(
    buyerCompHealthBenefitsAtom,
  );
  const totalCompensation = useAtomValue(buyerCompTotalAtom);

  return (
    <Stack gap={4}>
      <InputDollar
        value={Number(buyerSalary)}
        onChange={(val) => setBuyerSalary(toNumberOrNull(val))}
        label={
          <LabelExplainer
            label="Base Salary"
            explanation={<BuyersCompAnnualBaseSallaryExplainer />}
          />
        }
      />
      <InputDollar
        value={Number(buyerPayrollTaxes)}
        onChange={(val) => setBuyerPayrollTaxes(toNumberOrNull(val))}
        label={
          <LabelExplainer
            label="Payroll Taxes"
            explanation={<BuyersCompPayrollTaxesExplainer />}
          />
        }
      />
      <InputDollar
        value={Number(buyerHealthBenefits)}
        onChange={(val) => setBuyerHealthBenefits(toNumberOrNull(val))}
        label={
          <LabelExplainer
            label="Health Benefits"
            explanation={<BuyersCompHealthBenefitsExplainer />}
          />
        }
      />

      <ComputedResult
        label={
          <LabelExplainer
            label=" Total Compensation"
            explanation={
              <Doc path="@/docs/pages/model/sidebar/buyersCompTotalCompensation.mdx" />
            }
          />
        }
        value={formatDollar(toNumberOrNull(totalCompensation) || 0)}
      />
    </Stack>
  );
};

export const ModuleBuyersCompensation = {
  key: "Annual Buyer's Compensation",
  icon: <TbCoins />,
  control: "Buyer's Compensation",
  panel: <Panel />,
};
