import { Doc } from "@/components/Doc/Doc";
import { LabelExplainer } from "@/components/LabelExplainer/LabelExplainer";
import {
  amortOfDealExpensesMolecule,
  annualMaintenanceCapExMolecule,
  buyersCompMolecule,
  companysTaxRateMolecule,
  costOfGoodsSoldItemization,
  depreciationAndAmortizationMolecule,
  distributableFreeCashFlowMolecule,
  ebitdaMarginsMolecule,
  ebitdaMolecule,
  grossMarginsMolecule,
  grossProfitMolecule,
  grossRevenueItemization,
  grossRevenueYoYGrowth,
  incomeTaxesMolecule,
  lineOfCreditEndBalanceMolecule,
  lineOfCreditInterestPaymentMolecule,
  lineOfCreditPaymentMolecule,
  lineOfCreditPrincipalPaymentMolecule,
  lineOfCreditStartBalanceMolecule,
  netIncomeMolecule,
  operatingExpensesItemization,
  operatingIncomeMolecule,
  proceedsFromCompanySaleMolecule,
  sba7aLoanExpenseEndingBalanceMolecule,
  sba7aLoanExpenseExtraPaymentsMolecule,
  sba7aLoanExpenseInterestMolecule,
  sba7aLoanExpenseInterestPaymentMolecule,
  sba7aLoanExpensePrincipalMolecule,
  sba7aLoanExpenseStartingBalanceMolecule,
  sba7aLoanPaymentMolecule,
  sellersNoteExpenseEndingBalanceMolecule,
  sellersNoteExpenseExtraPaymentsMolecule,
  sellersNoteExpenseInterestMolecule,
  sellersNoteExpenseInterestPaymentMolecule,
  sellersNoteExpensePrincipalMolecule,
  sellersNoteExpenseStartingBalanceMolecule,
  sellersNoteLoanPaymentMolecule,
  taxableIncomeMolecule,
  totalDebtServiceMolecule,
  totalEquityInvestorsDistributionsMolecule,
  totalEquityInvestorsProceedsFromCompanySaleMolecule,
  totalEquityPreferredDividendsMolecule,
  totalEquityPrincipalRepaymentsMolecule,
  totalEquityProfitDistributionsMolecule,
  unleveredFreeCashFlowMolecule,
  useableFreeCashFlowMolecule,
} from "@/financeModels/owasco/projections";
import { Divider } from "@mantine/core";
import { CellRow } from "../CellGrid/CellRow";
import { Grid } from "../CellGrid/Grid";
import { Itemization } from "../CellGrid/Itemization";
import { TitleRow } from "../CellGrid/TitleRow";
import { Waterfall } from "../CellGrid/Waterfall";
import { IterationRow } from "./IterationRow";

export const DataGrid = () => {
  let tabYIndex = 0;

  return (
    <Grid w={"100%"} h={"100%"}>
      <IterationRow />
      <TitleRow>Company Financials</TitleRow>
      <Waterfall>
        <Itemization
          itemizationAtom={grossRevenueItemization}
          tabY={tabYIndex++}
          label={
            <LabelExplainer
              showIcon={false}
              label={`Gross Revenue`}
              explanation={
                <Doc path="@/docs/pages/model/projections/gross-revenue.mdx" />
              }
            />
          }
        />
        <CellRow
          molecule={grossRevenueYoYGrowth}
          label={
            <LabelExplainer
              showIcon={false}
              label={`Gross Revenue YoY Growth`}
              explanation={
                <Doc path="@/docs/pages/model/projections/gross-revenue-yoy-growth.mdx" />
              }
            />
          }
          tabY={tabYIndex++}
          labelWidth={220}
          variant="transparent"
        />
        <Itemization
          itemizationAtom={costOfGoodsSoldItemization}
          label={
            <LabelExplainer
              showIcon={false}
              label={`Cost of Goods Sold (COGS)`}
              explanation={
                <Doc path="@/docs/pages/model/projections/cost-of-goods-sold.mdx" />
              }
            />
          }
          tabY={tabYIndex++}
        />
        <CellRow
          molecule={grossProfitMolecule}
          bold
          label={
            <Waterfall.Formula
              explanation={
                <Doc path={"@/docs/pages/model/projections/gross-profit.mdx"} />
              }
            >
              Gross Profit
            </Waterfall.Formula>
          }
          tabY={tabYIndex++}
        />
      </Waterfall>
      <CellRow
        molecule={grossMarginsMolecule}
        label={
          <LabelExplainer
            showIcon={false}
            label={`Gross Margins`}
            explanation={
              <Doc path="@/docs/pages/model/projections/gross-margins.mdx" />
            }
          />
        }
        tabY={tabYIndex++}
        labelWidth={140}
        variant="transparent"
      />
      <Divider my="xs" />
      <Waterfall>
        <Itemization
          itemizationAtom={operatingExpensesItemization}
          label={
            <LabelExplainer
              showIcon={false}
              label={`Operating Expenses`}
              explanation={
                <Doc path="@/docs/pages/model/projections/operating-expenses.mdx" />
              }
            />
          }
          tabY={tabYIndex++}
        />

        <CellRow
          molecule={buyersCompMolecule}
          label={
            <LabelExplainer
              showIcon={false}
              label={`Buyer's Compensation`}
              explanation={
                <Doc path="@/docs/pages/model/projections/buyers-compensation.mdx" />
              }
            />
          }
          tabY={tabYIndex++}
        />

        <CellRow
          molecule={ebitdaMolecule}
          bold
          label={
            <Waterfall.Formula
              explanation={
                <Doc path={"@/docs/pages/model/projections/ebitda.mdx"} />
              }
            >
              EBITDA
            </Waterfall.Formula>
          }
          tabY={tabYIndex++}
        />
      </Waterfall>
      <CellRow
        molecule={ebitdaMarginsMolecule}
        label={
          <LabelExplainer
            showIcon={false}
            label={`EBITDA Margins`}
            explanation={
              <Doc path="@/docs/pages/model/projections/ebitda-margins.mdx" />
            }
          />
        }
        tabY={tabYIndex++}
        labelWidth={150}
        variant="transparent"
      />
      <Divider my="xs" />
      <Waterfall>
        <CellRow
          molecule={depreciationAndAmortizationMolecule}
          label={
            <LabelExplainer
              showIcon={false}
              label={`Depreciation & Amortization`}
              explanation={
                <Doc path="@/docs/pages/model/projections/depreciation-and-amortization.mdx" />
              }
            />
          }
          tabY={tabYIndex++}
        />
        <CellRow
          molecule={amortOfDealExpensesMolecule}
          label={
            <LabelExplainer
              showIcon={false}
              label={`Amortization of Deal Expenses`}
              explanation={
                <Doc path="@/docs/pages/model/projections/amortization-of-deal-expenses.mdx" />
              }
            />
          }
          tabY={tabYIndex++}
        />
        <CellRow
          molecule={operatingIncomeMolecule}
          bold
          label={
            <Waterfall.Formula
              explanation={
                <Doc
                  path={"@/docs/pages/model/projections/operating-income.mdx"}
                />
              }
            >
              Operating Income
            </Waterfall.Formula>
          }
          tabY={tabYIndex++}
        />
      </Waterfall>
      <Divider my="xs" />
      <Waterfall>
        <Waterfall.Collapse
          label={"Income Taxes"}
          explanation={
            <Doc path={"@/docs/pages/model/projections/income-taxes.mdx"} />
          }
          tabY={tabYIndex++}
          molecule={incomeTaxesMolecule}
        >
          <CellRow
            tabY={tabYIndex++}
            molecule={annualMaintenanceCapExMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={`Annual Maintenance CapEx`}
                explanation={
                  <Doc path="@/docs/pages/model/projections/annual-maintenance-capex.mdx" />
                }
              />
            }
          />

          <CellRow
            tabY={tabYIndex++}
            molecule={sba7aLoanExpenseInterestMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={`Interest - SBA 7(a) Loan`}
                explanation={
                  <Doc path="@/docs/pages/model/projections/sba-7a-loan-interest.mdx" />
                }
              />
            }
          />
          <CellRow
            tabY={tabYIndex++}
            molecule={sellersNoteExpenseInterestMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={`Interest - Seller's Note`}
                explanation={
                  <Doc path="@/docs/pages/model/projections/sellers-note-interest.mdx" />
                }
              />
            }
          />
          <CellRow
            tabY={tabYIndex++}
            molecule={taxableIncomeMolecule}
            bold
            label={
              <Waterfall.Formula
                explanation={
                  <Doc
                    path={"@/docs/pages/model/projections/taxable-income.mdx"}
                  />
                }
              >
                Taxable Income
              </Waterfall.Formula>
            }
          />
          <CellRow
            tabY={tabYIndex++}
            molecule={companysTaxRateMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={`Company's Tax Rate`}
                explanation={
                  <Doc path="@/docs/pages/model/projections/companys-tax-rates.mdx" />
                }
              />
            }
          />
        </Waterfall.Collapse>

        <CellRow
          molecule={sba7aLoanExpenseInterestMolecule}
          label={
            <LabelExplainer
              showIcon={false}
              label={`Interest - SBA 7(a) Loan`}
              explanation={
                <Doc path="@/docs/pages/model/projections/sba-7a-loan-interest.mdx" />
              }
            />
          }
          tabY={tabYIndex++}
        />
        <CellRow
          molecule={sellersNoteExpenseInterestMolecule}
          label={
            <LabelExplainer
              showIcon={false}
              label={`Interest - Seller's Note`}
              explanation={
                <Doc path="@/docs/pages/model/projections/sellers-note-interest.mdx" />
              }
            />
          }
          tabY={tabYIndex++}
        />

        <CellRow
          molecule={netIncomeMolecule}
          bold
          label={
            <Waterfall.Formula
              explanation={
                <Doc path={"@/docs/pages/model/projections/net-income.mdx"} />
              }
            >
              Net Income
            </Waterfall.Formula>
          }
          tabY={tabYIndex++}
        />
      </Waterfall>

      <Divider my="xs" />

      <TitleRow>Debt Service</TitleRow>

      <Waterfall>
        <Waterfall.Collapse
          label={"Senior Debt - SBA 7(a) Loan"}
          explanation={
            <Doc
              path={
                "@/docs/pages/model/projections/sba7a-loan-total-payments.mdx"
              }
            />
          }
          tabY={tabYIndex++}
          molecule={sba7aLoanPaymentMolecule}
        >
          <CellRow
            molecule={sba7aLoanExpenseStartingBalanceMolecule}
            label={"SBA 7(a) - Beginning Balance"}
            tabY={tabYIndex++}
            variant="transparent"
            labelWidth={240}
          />
          <CellRow
            molecule={sba7aLoanExpenseInterestPaymentMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={`SBA 7(a) - Interest Payment`}
                explanation={
                  <Doc path="@/docs/pages/model/projections/sba-7a-loan-interest.mdx" />
                }
              />
            }
            tabY={tabYIndex++}
          />
          <CellRow
            molecule={sba7aLoanExpensePrincipalMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={`SBA 7(a) - Principal Payment`}
                explanation={
                  <Doc path="@/docs/pages/model/projections/sba-7a-loan-principal.mdx" />
                }
              />
            }
            tabY={tabYIndex++}
          />
          <CellRow
            molecule={sba7aLoanExpenseExtraPaymentsMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={`SBA 7(a) - Extra Payments`}
                explanation={
                  <Doc path="@/docs/pages/model/projections/sba-7a-loan-extra-payments.mdx" />
                }
              />
            }
            tabY={tabYIndex++}
          />
          <CellRow
            molecule={sba7aLoanExpenseEndingBalanceMolecule}
            label={"SBA 7(a) - Ending Balance"}
            tabY={tabYIndex++}
            variant="transparent"
            labelWidth={220}
          />
        </Waterfall.Collapse>
        <Waterfall.Collapse
          label={"Line of Credit"}
          explanation={
            <Doc
              path={
                "@/docs/pages/model/projections/line-of-credit-total-payments.mdx"
              }
            />
          }
          tabY={tabYIndex++}
          molecule={lineOfCreditPaymentMolecule}
        >
          <CellRow
            molecule={lineOfCreditStartBalanceMolecule}
            label={"Line of Credit - Beginning Balance"}
            tabY={tabYIndex++}
            variant="transparent"
            labelWidth={250}
          />
          <CellRow
            molecule={lineOfCreditInterestPaymentMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={`Line of Credit - Interest Payment`}
                explanation={
                  <Doc path="@/docs/pages/model/projections/line-of-credit-interest.mdx" />
                }
              />
            }
            tabY={tabYIndex++}
          />
          <CellRow
            molecule={lineOfCreditPrincipalPaymentMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={`Line of Credit - Principal Payment`}
                explanation={
                  <Doc path="@/docs/pages/model/projections/line-of-credit-principal.mdx" />
                }
              />
            }
            tabY={tabYIndex++}
          />
          <CellRow
            molecule={lineOfCreditEndBalanceMolecule}
            label={"Line of Credit - Ending Balance"}
            tabY={tabYIndex++}
            variant="transparent"
            labelWidth={230}
          />
        </Waterfall.Collapse>
        <Waterfall.Collapse
          label={"Seller's Note - Loan"}
          explanation={
            <Doc
              path={
                "@/docs/pages/model/projections/sellers-note-total-payments.mdx"
              }
            />
          }
          tabY={tabYIndex++}
          molecule={sellersNoteLoanPaymentMolecule}
        >
          <CellRow
            molecule={sellersNoteExpenseStartingBalanceMolecule}
            label={"Seller's Note - Beginning Balance"}
            tabY={tabYIndex++}
            variant="transparent"
            labelWidth={250}
          />
          <CellRow
            molecule={sellersNoteExpenseInterestPaymentMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={`Seller's Note - Interest Payment`}
                explanation={
                  <Doc path="@/docs/pages/model/projections/sellers-note-interest.mdx" />
                }
              />
            }
            tabY={tabYIndex++}
          />
          <CellRow
            molecule={sellersNoteExpensePrincipalMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={`Seller's Note - Principal Payment`}
                explanation={
                  <Doc path="@/docs/pages/model/projections/sellers-note-principal.mdx" />
                }
              />
            }
            tabY={tabYIndex++}
          />
          <CellRow
            molecule={sellersNoteExpenseExtraPaymentsMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={`Seller's Note - Extra Payments`}
                explanation={
                  <Doc path="@/docs/pages/model/projections/sellers-note-extra-payments.mdx" />
                }
              />
            }
            tabY={tabYIndex++}
          />
          <CellRow
            molecule={sellersNoteExpenseEndingBalanceMolecule}
            label={"Seller's Note - Ending Balance"}
            tabY={tabYIndex++}
            variant="transparent"
            labelWidth={230}
          />
        </Waterfall.Collapse>
        <CellRow
          molecule={totalDebtServiceMolecule}
          bold
          label={
            <Waterfall.Formula
              explanation={
                <Doc
                  path={"@/docs/pages/model/projections/total-debt-service.mdx"}
                />
              }
            >
              Total Debt Service
            </Waterfall.Formula>
          }
          tabY={tabYIndex++}
        />
      </Waterfall>

      <Divider my="xs" />

      <TitleRow>Cash Flow Metrics & Distributions</TitleRow>

      <Waterfall.Collapse
        molecule={unleveredFreeCashFlowMolecule}
        label={"Unlevered Free Cash Flow"}
        explanation={
          <Doc
            path={"@/docs/pages/model/projections/unlevered-free-cash-flow.mdx"}
          />
        }
        tabY={tabYIndex++}
      >
        <CellRow
          bold
          label={
            <Waterfall.Formula
              explanation={
                <Doc path={"@/docs/pages/model/projections/ebitda.mdx"} />
              }
            >
              EBITDA
            </Waterfall.Formula>
          }
          tabY={tabYIndex++}
          molecule={ebitdaMolecule}
        />
        <CellRow
          bold
          label={
            <Waterfall.Formula
              explanation={
                <Doc path={"@/docs/pages/model/projections/income-taxes.mdx"} />
              }
            >
              Income Taxes
            </Waterfall.Formula>
          }
          tabY={tabYIndex++}
          molecule={incomeTaxesMolecule}
        />
        <CellRow
          label={
            <LabelExplainer
              showIcon={false}
              label={`Annual Maintenance CapEx`}
              explanation={
                <Doc path="@/docs/pages/model/projections/annual-maintenance-capex.mdx" />
              }
            />
          }
          tabY={tabYIndex++}
          molecule={annualMaintenanceCapExMolecule}
        />
        <CellRow
          label={
            <LabelExplainer
              showIcon={false}
              label={`Gross Proceeds from Company Sale`}
              explanation={
                <Doc path="@/docs/pages/model/projections/proceeds-from-company-sale.mdx" />
              }
            />
          }
          tabY={tabYIndex++}
          molecule={proceedsFromCompanySaleMolecule}
        />
      </Waterfall.Collapse>

      <Divider my="xs" />

      <Waterfall.Collapse
        molecule={distributableFreeCashFlowMolecule}
        label={"Distributable Free Cash Flow"}
        explanation={
          <Doc
            path={
              "@/docs/pages/model/projections/distributable-free-cash-flow.mdx"
            }
          />
        }
        tabY={tabYIndex++}
      >
        <CellRow
          molecule={unleveredFreeCashFlowMolecule}
          bold
          label={
            <Waterfall.Formula
              explanation={
                <Doc
                  path={
                    "@/docs/pages/model/projections/unlevered-free-cash-flow.mdx"
                  }
                />
              }
            >
              Unlevered Free Cash Flow
            </Waterfall.Formula>
          }
          tabY={tabYIndex++}
        />
        <CellRow
          molecule={totalDebtServiceMolecule}
          bold
          label={
            <Waterfall.Formula
              explanation={
                <Doc
                  path={"@/docs/pages/model/projections/total-debt-service.mdx"}
                />
              }
            >
              Total Debt Service
            </Waterfall.Formula>
          }
          tabY={tabYIndex++}
        />
      </Waterfall.Collapse>
      <Divider my="xs" />
      <Waterfall.Collapse
        molecule={useableFreeCashFlowMolecule}
        label={"Usable Free Cash Flow"}
        explanation={
          <Doc
            path={"@/docs/pages/model/projections/usable-free-cash-flow.mdx"}
          />
        }
        tabY={tabYIndex++}
      >
        <CellRow
          molecule={distributableFreeCashFlowMolecule}
          label={
            <LabelExplainer
              showIcon={false}
              label={"Distributable Free Cash Flow"}
              explanation={
                <Doc path="@/docs/pages/model/projections/distributable-free-cash-flow.mdx" />
              }
            />
          }
          tabY={tabYIndex++}
        />
        <Waterfall.Collapse
          molecule={totalEquityInvestorsDistributionsMolecule}
          label={"Equity Investor Distributions"}
          explanation={
            <Doc
              path={
                "@/docs/pages/model/projections/equity-investor-distributions.mdx"
              }
            />
          }
          tabY={tabYIndex++}
        >
          <CellRow
            molecule={totalEquityPreferredDividendsMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={"Preferred Dividends"}
                explanation={
                  <Doc path="@/docs/pages/model/projections/equity-investor-prefered-dividends.mdx" />
                }
              />
            }
            tabY={tabYIndex++}
          />
          <CellRow
            molecule={totalEquityPrincipalRepaymentsMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={"Principal Repayments (Return of Capital)"}
                explanation={
                  <Doc path="@/docs/pages/model/projections/equity-investor-principal-repayments.mdx" />
                }
              />
            }
            tabY={tabYIndex++}
          />

          <CellRow
            molecule={totalEquityProfitDistributionsMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={"Profit Distributions"}
                explanation={
                  <Doc path="@/docs/pages/model/projections/equity-investor-profit-distributions.mdx" />
                }
              />
            }
            tabY={tabYIndex++}
          />
          <CellRow
            molecule={totalEquityInvestorsProceedsFromCompanySaleMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={"Investors’ Proceeds from Company Sale"}
                explanation={
                  <Doc path="@/docs/pages/model/projections/investor-proceeds-from-company-sale.mdx" />
                }
              />
            }
            tabY={tabYIndex++}
          />
        </Waterfall.Collapse>
      </Waterfall.Collapse>

      <Divider mb={"calc(25vh + 100px)"} />
    </Grid>
  );
};
