import { dismissedWelcomeVideoAtom } from "@/financeModels/owasco/general";
import { hasFinishedLoadingModelOnMountAtom } from "@/financeModels/owasco/save";
import { ActionIcon, AspectRatio, Modal } from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { IoClose } from "react-icons/io5";

export const WelcomeVideo = () => {
  const [dissmised, setState] = useAtom(dismissedWelcomeVideoAtom);
  const loaded = useAtomValue(hasFinishedLoadingModelOnMountAtom);

  const onClose = () => {
    setState(true);
  };

  if (!loaded) return null;

  return (
    <>
      <Modal
        opened={!dissmised}
        onClose={onClose}
        withCloseButton={false}
        size="auto"
        centered
        //trapFocus={false}
      >
        <AspectRatio ratio={16 / 9} w={"50dvw"} maw={800}>
          <iframe
            src="https://www.youtube.com/embed/WO2Igc3gq5A?si=aGDoEwZH1TQJ9OGk"
            title="Welcome to ETA IQ"
            style={{ border: 0 }}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </AspectRatio>
      </Modal>
      {!dissmised && (
        <ActionIcon
          onClick={onClose}
          top={10}
          right={10}
          pos={"absolute"}
          color="blue"
          style={{ zIndex: 1000 }}
          radius={99}
        >
          <IoClose />
        </ActionIcon>
      )}
    </>
  );
};
