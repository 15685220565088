import { Doc } from "@/components/Doc/Doc";
import { LabelExplainer } from "@/components/LabelExplainer/LabelExplainer";
import {
  isRollinDealExpensesAtom,
  isRollinLoanTransactionFeeAtom,
  isRollinSBAGuarantyFeeAtom,
  sbaLoanTotalWithOptionalFeesAtom,
} from "@/financeModels/owasco/general";
import { formatDollar } from "@/utils/format";
import { lazyReactFallback } from "@/utils/lazy";
import { useAtom, useAtomValue } from "jotai";
import { ChangeEvent } from "react";
import { ComputedResult } from "./ComputedResult";
import { InputToggle } from "./InputToggle";

const Sba7aLoanRollInLoanTransactionFeeExplainer = lazyReactFallback(
  () =>
    import("@/docs/pages/model/sidebar/sba7aLoanRollInLoanTransactionFee.mdx"),
);
const Sba7aLoanRollinSBAGuarantyFeeExplainer = lazyReactFallback(
  () => import("@/docs/pages/model/sidebar/sba7aLoanRollinSBAGuarantyFee.mdx"),
);

const Sba7aLoanRollinDealExpensesExplainer = lazyReactFallback(
  () => import("@/docs/pages/model/sidebar/sba7aLoanRollinDealExpenses.mdx"),
);

export const DealFees = () => {
  const [isRollinLoanTransactionFee, setRollinLoanTransactionFee] = useAtom(
    isRollinLoanTransactionFeeAtom,
  );
  const [isRollinSBAGuarantyFee, setRollinSBAGuarantyFee] = useAtom(
    isRollinSBAGuarantyFeeAtom,
  );

  const [isRollinDealExpenses, setRollinDealExpenses] = useAtom(
    isRollinDealExpensesAtom,
  );

  const sbaLoanTotalWithOptionalFees = useAtomValue(
    sbaLoanTotalWithOptionalFeesAtom,
  );

  return (
    <>
      <InputToggle
        label={
          <LabelExplainer
            label="Roll-in Loan Transation Fee"
            explanation={<Sba7aLoanRollInLoanTransactionFeeExplainer />}
          />
        }
        checked={isRollinLoanTransactionFee}
        onChange={(event) =>
          setRollinLoanTransactionFee(
            (event as ChangeEvent<HTMLInputElement>).currentTarget.checked,
          )
        }
      />

      <InputToggle
        label={
          <LabelExplainer
            label="Roll-in SBA's Guaranty Fee"
            explanation={<Sba7aLoanRollinSBAGuarantyFeeExplainer />}
          />
        }
        checked={isRollinSBAGuarantyFee}
        onChange={(event) =>
          setRollinSBAGuarantyFee(
            (event as ChangeEvent<HTMLInputElement>).currentTarget.checked,
          )
        }
      />

      <InputToggle
        label={
          <LabelExplainer
            label="Roll-in Deal Expenses"
            explanation={<Sba7aLoanRollinDealExpensesExplainer />}
          />
        }
        checked={isRollinDealExpenses}
        onChange={(event) =>
          setRollinDealExpenses(
            (event as ChangeEvent<HTMLInputElement>).currentTarget.checked,
          )
        }
      />

      <ComputedResult
        label={
          <LabelExplainer
            label="SBA 7(a) Loan Total"
            explanation={
              <Doc path="@/docs/pages/model/sidebar/sba7aLoanTotal.mdx" />
            }
          />
        }
        value={formatDollar(sbaLoanTotalWithOptionalFees)}
      />
    </>
  );
};
