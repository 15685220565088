import { evaluteWithErrors as solve, toNumberOrNull } from "@/utils/math";
import { irr } from "@travishorn/finance";
import { Atom, Getter, atom } from "jotai";
import { isNumber } from "mathjs";
import { DollarAmount, MathResult, PercentAmount } from "../types";
import {
  sbaLoanAmortizationPaymentsAtom,
  sellersNoteAmortizationPaymentsAtom,
} from "./amortization";
import {
  companySaleIncludedAtom,
  companySaleYearAtom,
  equityDollarAtom,
  equityInvestorDollarAtom,
  equityInvestorEquityStepUpAtom,
  equityInvestorPreferredEquityRateAtom,
  equityInvestorStartPreferredDividendsAtom,
  equityInvestorStartPrincipalRepaymentsAtom,
  individualInvestorReturnStartDollarAtom,
  isEquityInvestorsIncludedAtom,
  sbaLoanStartDateAtom,
} from "./general";
import {
  annualMaintenanceCapExMolecule,
  distributableFreeCashFlowMolecule,
  ebitdaMolecule,
  incomeTaxesMolecule,
  lineOfCreditEndBalanceMolecule,
  proceedsFromCompanySaleMolecule,
  sba7aLoanPaymentMolecule,
  sellersNoteLoanPaymentMolecule,
  useableFreeCashFlowMolecule,
} from "./projections";
import { sourcesTotalCostAtom } from "./sourcesAndUses";
import { CellTypes } from "./types";

export const totalEquityDollarAtom = atom((get) =>
  solve<DollarAmount>("a + b", {
    a: get(equityInvestorDollarAtom),
    b: get(equityDollarAtom),
  }),
);

const basePreferredEquitySearcherPercent = (get: Getter) =>
  solve<PercentAmount>("a / b * c", {
    a: get(equityDollarAtom),
    b: get(sourcesTotalCostAtom),
    c: get(equityInvestorEquityStepUpAtom),
  });

const basePreferredEquityInvestorPercent = (get: Getter) =>
  solve<PercentAmount>("a / b * c", {
    a: get(equityInvestorDollarAtom),
    b: get(sourcesTotalCostAtom),
    c: get(equityInvestorEquityStepUpAtom),
  });

const baseTotalEquityPreferredPercent = (get: Getter) =>
  solve<PercentAmount>("a + b", {
    a: basePreferredEquitySearcherPercent(get),
    b: basePreferredEquityInvestorPercent(get),
  });

export const preferredEquitySearcherPercentAtom = atom((get) => {
  const total = toNumberOrNull(baseTotalEquityPreferredPercent(get)) || 0;
  const searcher = basePreferredEquitySearcherPercent(get);

  if (!get(isEquityInvestorsIncludedAtom)) return 0.0000000000001;

  if (total > 1)
    return solve<PercentAmount>("a / b", {
      a: searcher,
      b: total,
    });

  return searcher;
});

export const preferredEquityInvestorPercentAtom = atom((get) => {
  const total = toNumberOrNull(baseTotalEquityPreferredPercent(get)) || 0;
  const investor = basePreferredEquityInvestorPercent(get);

  if (total > 1)
    return solve<PercentAmount>("a / b", {
      a: investor,
      b: total,
    });

  return investor;
});

export const totalEquityPreferredPercentAtom = atom((get) =>
  solve<PercentAmount>("a + b", {
    a: get(preferredEquitySearcherPercentAtom),
    b: get(preferredEquityInvestorPercentAtom),
  }),
);

export const commonEquitySearcherPercentAtom = atom((get) =>
  solve<PercentAmount>("1 - a - b", {
    a: get(preferredEquitySearcherPercentAtom),
    b: get(preferredEquityInvestorPercentAtom),
  }),
);

export const preferredEquityExternalInvestorsTotalPercentAtom = atom((get) =>
  solve<PercentAmount>("a / b", {
    a: get(equityInvestorDollarAtom),
    b: get(totalEquityDollarAtom),
  }),
);

export const preferredEquitySearcherTotalPercentAtom = atom((get) =>
  solve<PercentAmount>("a / b", {
    a: get(equityDollarAtom),
    b: get(totalEquityDollarAtom),
  }),
);

export const searcherPreferredAndCommonEquityPercentAtom = atom((get) =>
  solve<PercentAmount>("a + b", {
    a: get(preferredEquitySearcherPercentAtom),
    b: get(commonEquitySearcherPercentAtom),
  }),
);

const getMoleculeValue = (
  get: Getter,
  i: number,
  molecule: Atom<Atom<CellTypes>[]>,
) => {
  const atoms = get(molecule).slice(2);
  return atoms[i] ? toNumberOrNull(get(atoms[i]).value) || 0 : 0;
};

//table 1
export const equityReturnsAllInvestorsAtom = atom((get) => {
  let prev = {
    year: 0,
    period: 0,
    beginningBalance: 0,
    endingBalance: 0,
    preferredDividends: 0,
    principalRepayments: 0,
    profitDistributions: 0,
    proceedsFromCompanySale: 0,
    taxDistributions: 0,
    totalEquityInvestorDistributions: 0,
    principalHasBeenRepaid: false,
  };

  const isEquityInvestorsIncluded = get(isEquityInvestorsIncludedAtom);
  const isCompanySaleIncluded = get(companySaleIncludedAtom);
  const companySaleYear = isCompanySaleIncluded
    ? toNumberOrNull(get(companySaleYearAtom)) || -1
    : 999999;
  const distributableFreeCashFlow = get(distributableFreeCashFlowMolecule);

  // remove first item because its base year,
  // second item because it is the growth rate atom
  let distributableFreeCashAtoms = distributableFreeCashFlow.slice(2);
  // in order to build dep tree one item is needed on render
  if (distributableFreeCashAtoms.length === 0) {
    distributableFreeCashAtoms = [
      atom(() => {
        return { value: 0 } as CellTypes;
      }),
    ];
  }

  const startDate = get(sbaLoanStartDateAtom);
  const startYear = startDate.toDate().getFullYear();
  const startPreferredDividendsYear =
    toNumberOrNull(get(equityInvestorStartPreferredDividendsAtom)) || 1;
  const startPrincipalRepaymentsYear =
    toNumberOrNull(get(equityInvestorStartPrincipalRepaymentsAtom)) || 1;

  const investorPreferredEquityRate =
    toNumberOrNull(get(equityInvestorPreferredEquityRateAtom)) || 0;

  const totalEquityPreferredPercent =
    toNumberOrNull(get(totalEquityPreferredPercentAtom)) || 0;

  const totalEquityDollar = toNumberOrNull(get(totalEquityDollarAtom)) || 0;
  let totalEquityDollarTally = 0;

  //  return distributableFreeCashAtoms.map((distributableFreeCashAtom, i) => {
  const results = [];

  for (let i = 0; i < distributableFreeCashAtoms.length; i++) {
    const distributableFreeCashAtom = distributableFreeCashAtoms[i];

    const period = i + 1;
    const beginningBalance =
      toNumberOrNull(
        i <= 1 ? get(totalEquityDollarAtom) : prev.endingBalance,
      ) || 0;

    const distributableFreeCashFlow = Math.max(
      0,
      Number(get(distributableFreeCashAtom).value) || 0,
    );

    const proceedsByYear = getMoleculeValue(
      get,
      i,
      proceedsFromCompanySaleMolecule,
    );

    let proceedsFromCompanySale =
      !isCompanySaleIncluded || proceedsByYear <= 0
        ? 0
        : (proceedsByYear - get(totalOutstandingDebtFromCompanySaleAtom)) *
          totalEquityPreferredPercent;

    let preferredDividends = Math.min(
      distributableFreeCashFlow,
      beginningBalance * investorPreferredEquityRate,
    );

    if (distributableFreeCashFlow < 0) preferredDividends = 0;
    if (period < startPreferredDividendsYear) preferredDividends = 0;

    let principalRepayments = Math.min(
      beginningBalance,
      distributableFreeCashFlow - preferredDividends,
    );

    if (distributableFreeCashFlow - preferredDividends < 0)
      principalRepayments = 0;
    if (distributableFreeCashFlow < 0) principalRepayments = 0;
    if (period < startPrincipalRepaymentsYear) principalRepayments = 0;

    const hasAlreadySold =
      i >= companySaleYear && companySaleYear > 0 && isCompanySaleIncluded;

    if (!isEquityInvestorsIncluded || hasAlreadySold) {
      preferredDividends = 0;
      principalRepayments = 0;
      proceedsFromCompanySale = 0;
    }

    totalEquityDollarTally += principalRepayments;

    const principalHasBeenRepaid = totalEquityDollarTally >= totalEquityDollar;

    let profitDistributionsTotalDollar = Math.max(
      0,
      distributableFreeCashFlow - preferredDividends - principalRepayments,
    );

    const incomeTaxes = getMoleculeValue(get, i, incomeTaxesMolecule);

    if (proceedsFromCompanySale > 0 && isCompanySaleIncluded) {
      const ebitda = getMoleculeValue(get, i, ebitdaMolecule);
      const capEx = getMoleculeValue(get, i, annualMaintenanceCapExMolecule);
      const sba7aLoan = getMoleculeValue(get, i, sba7aLoanPaymentMolecule);
      const lineOfCredit = getMoleculeValue(
        get,
        i,
        lineOfCreditEndBalanceMolecule,
      );

      const sellersNote = getMoleculeValue(
        get,
        i,
        sellersNoteLoanPaymentMolecule,
      );

      profitDistributionsTotalDollar = Math.max(
        0,
        ebitda -
          incomeTaxes -
          capEx -
          sba7aLoan -
          lineOfCredit -
          sellersNote -
          preferredDividends -
          principalRepayments,
      );
    }

    const profitDistributions =
      !principalHasBeenRepaid || i >= companySaleYear
        ? 0
        : profitDistributionsTotalDollar * totalEquityPreferredPercent;

    const endingBalance = Math.max(0, beginningBalance - principalRepayments);

    const taxDistributions = !isEquityInvestorsIncluded
      ? 0
      : incomeTaxes * totalEquityPreferredPercent;

    const totalEquityInvestorDistributions =
      preferredDividends +
      principalRepayments +
      profitDistributions +
      taxDistributions +
      proceedsFromCompanySale;

    prev = {
      period,
      year: startYear + i,
      beginningBalance,
      endingBalance,
      principalRepayments,
      preferredDividends,
      profitDistributions,
      proceedsFromCompanySale,
      taxDistributions,
      totalEquityInvestorDistributions,
      principalHasBeenRepaid,
    };

    results.push({ ...prev });

    if (proceedsFromCompanySale > 0) break;
  }

  return results;
});

export const equityReturnsAllInvestorsTotalsAtom = atom((get) => {
  const returns = get(equityReturnsAllInvestorsAtom);

  return returns.reduce(
    (acc, metric) => {
      acc.preferredDividends += metric.preferredDividends;
      acc.principalRepayments += metric.principalRepayments;
      acc.profitDistributions += metric.profitDistributions;
      acc.taxDistributions += metric.taxDistributions;
      acc.proceedsFromCompanySale += metric.proceedsFromCompanySale;
      acc.totalEquityInvestorDistributions +=
        metric.totalEquityInvestorDistributions;

      return acc;
    },
    {
      preferredDividends: 0,
      principalRepayments: 0,
      profitDistributions: 0,
      taxDistributions: 0,
      proceedsFromCompanySale: 0,
      totalEquityInvestorDistributions: 0,
    },
  );
});

// table 2
export const equityReturnsExternalInvestorsAtom = atom((get) => {
  const returns = get(equityReturnsAllInvestorsAtom);
  const percent =
    toNumberOrNull(get(preferredEquityExternalInvestorsTotalPercentAtom)) || 0;

  return returns.map((item) => {
    const value = {
      preferredDividends: item.preferredDividends * percent,
      principalRepayments: item.principalRepayments * percent,
      profitDistributions: item.profitDistributions * percent,
      taxDistributions: item.taxDistributions * percent,
      proceedsFromCompanySale: item.proceedsFromCompanySale * percent,
    };

    return {
      period: item.period,
      year: item.year,
      ...value,
      totalEquityInvestorDistributions: Object.values(value).reduce(
        (acc, val) => acc + val,
        0,
      ),
    };
  });
});

export const equityReturnsExternalInvestorsTotalsAtom = atom((get) => {
  const returns = get(equityReturnsExternalInvestorsAtom);

  return returns.reduce(
    (acc, metric) => {
      acc.preferredDividends += metric.preferredDividends;
      acc.principalRepayments += metric.principalRepayments;
      acc.profitDistributions += metric.profitDistributions;
      acc.taxDistributions += metric.taxDistributions;
      acc.proceedsFromCompanySale += metric.proceedsFromCompanySale;
      acc.totalEquityInvestorDistributions +=
        metric.totalEquityInvestorDistributions;

      return acc;
    },
    {
      preferredDividends: 0,
      principalRepayments: 0,
      profitDistributions: 0,
      taxDistributions: 0,
      proceedsFromCompanySale: 0,
      totalEquityInvestorDistributions: 0,
    },
  );
});

// table 3a
export const equityReturnsSearcherAtom = atom((get) => {
  const returns = get(equityReturnsAllInvestorsAtom);
  const percent =
    toNumberOrNull(get(preferredEquitySearcherTotalPercentAtom)) || 0;

  return returns.map((item) => {
    const value = {
      preferredDividends: item.preferredDividends * percent,
      principalRepayments: item.principalRepayments * percent,
      profitDistributions: item.profitDistributions * percent,
      taxDistributions: item.taxDistributions * percent,
      proceedsFromCompanySale: item.proceedsFromCompanySale * percent,
    };

    return {
      period: item.period,
      year: item.year,
      ...value,
      totalEquityInvestorDistributions: Object.values(value).reduce(
        (acc, val) => acc + val,
        0,
      ),
    };
  });
});

export const equityReturnsSearcherTotalsAtom = atom((get) => {
  const returns = get(equityReturnsSearcherAtom);

  return returns.reduce(
    (acc, metric) => {
      acc.preferredDividends += metric.preferredDividends;
      acc.principalRepayments += metric.principalRepayments;
      acc.profitDistributions += metric.profitDistributions;
      acc.taxDistributions += metric.taxDistributions;
      acc.proceedsFromCompanySale += metric.proceedsFromCompanySale;
      acc.totalEquityInvestorDistributions +=
        metric.totalEquityInvestorDistributions;

      return acc;
    },
    {
      preferredDividends: 0,
      principalRepayments: 0,
      profitDistributions: 0,
      taxDistributions: 0,
      proceedsFromCompanySale: 0,
      totalEquityInvestorDistributions: 0,
    },
  );
});

// table 3b and 4
export const equityReturnsCommonEquityAtom = atom((get) => {
  const returns = get(equityReturnsAllInvestorsAtom);
  const percent = toNumberOrNull(get(commonEquitySearcherPercentAtom)) || 0;
  const totalEquityPreferredPercent =
    toNumberOrNull(get(totalEquityPreferredPercentAtom)) || 0;
  const isCompanySaleIncluded = get(companySaleIncludedAtom);

  return returns.map((item, i) => {
    const proceedsDollar = getMoleculeValue(
      get,
      i,
      proceedsFromCompanySaleMolecule,
    );

    let proceedsFromCompanySale =
      !isCompanySaleIncluded || proceedsDollar <= 0
        ? 0
        : (proceedsDollar - get(totalOutstandingDebtFromCompanySaleAtom)) *
          percent;

    let profitDistributions = getMoleculeValue(
      get,
      i,
      useableFreeCashFlowMolecule,
    );

    if (proceedsFromCompanySale > 0) {
      profitDistributions =
        item.profitDistributions / totalEquityPreferredPercent -
        item.profitDistributions;

      if (profitDistributions <= 0.00001)
        proceedsFromCompanySale = getMoleculeValue(
          get,
          i,
          useableFreeCashFlowMolecule,
        );
    }

    const value = {
      profitDistributions,
      proceedsFromCompanySale,
    };

    return {
      period: item.period,
      year: item.year,
      ...value,
      totalEquityInvestorDistributions: Object.values(value).reduce(
        (acc, val) => acc + val,
        0,
      ),
    };
  });
});

export const equityReturnsCommonEquityTotalsAtom = atom((get) => {
  const returns = get(equityReturnsCommonEquityAtom);

  return returns.reduce(
    (acc, metric) => {
      acc.profitDistributions += metric.profitDistributions;
      acc.proceedsFromCompanySale += metric.proceedsFromCompanySale;
      acc.totalEquityInvestorDistributions +=
        metric.totalEquityInvestorDistributions;

      return acc;
    },
    {
      profitDistributions: 0,
      proceedsFromCompanySale: 0,
      totalEquityInvestorDistributions: 0,
    },
  );
});

const calcIrr = (
  get: Getter,
  initialDollarAtom: Atom<MathResult>,
  tableAtom:
    | typeof equityReturnsSearcherAtom
    | typeof equityReturnsCommonEquityAtom,
) => {
  const initialDollarValue = -(toNumberOrNull(get(initialDollarAtom)) || 0);
  const irrTable = get(tableAtom);

  const irrValues = [
    initialDollarValue,
    ...irrTable.map((item) => item.totalEquityInvestorDistributions),
  ].filter((v) => isNumber(v));

  const isValid = irrValues.every((v) => isNumber(v));
  const sum = irrValues.reduce((acc, val) => acc + val, 0);

  try {
    const irrResult = sum !== 0 && isValid ? irr(irrValues) : null;
    return irrResult as MathResult;
  } catch (e) {
    console.log(e);
  }

  return null;
};

const calcMoic = (
  get: Getter,
  initialDollarAtom: Atom<MathResult>,
  tableAtom:
    | typeof equityReturnsSearcherAtom
    | typeof equityReturnsCommonEquityAtom,
) => {
  const initialDollarValue = toNumberOrNull(get(initialDollarAtom)) || 0;
  const irrTable = get(tableAtom);

  const sumOfTotalInvestorDistributions = irrTable.reduce(
    (acc, item) => acc + item.totalEquityInvestorDistributions,
    0,
  );

  return solve<PercentAmount>("a / b", {
    a: sumOfTotalInvestorDistributions,
    b: initialDollarValue,
  });
};

export const metricsPreferredEquityAtom = atom((get) => {
  const totals = get(equityReturnsSearcherTotalsAtom);
  const saleProceeds = totals.proceedsFromCompanySale;
  const totalReturn = totals.totalEquityInvestorDistributions;
  const totalDistributions =
    totals.totalEquityInvestorDistributions - saleProceeds;

  return {
    companyOwnership: get(preferredEquitySearcherPercentAtom),
    saleProceeds,
    totalDistributions,
    totalReturn,
    irr: calcIrr(get, equityDollarAtom, equityReturnsSearcherAtom),
    moic: calcMoic(get, equityDollarAtom, equityReturnsSearcherAtom),
  };
});

export const metricsCommonEquityAtom = atom((get) => {
  const totals = get(equityReturnsCommonEquityTotalsAtom);
  const saleProceeds = totals.proceedsFromCompanySale;

  return {
    companyOwnership: get(commonEquitySearcherPercentAtom),
    saleProceeds,
    totalDistributions: totals.totalEquityInvestorDistributions - saleProceeds,
    totalReturn: totals.totalEquityInvestorDistributions,
    irr: calcIrr(get, equityDollarAtom, equityReturnsCommonEquityAtom),
    moic: calcMoic(get, equityDollarAtom, equityReturnsCommonEquityAtom),
  };
});

export const metricsSearcherEquityAtom = atom((get) => {
  const m1 = get(metricsPreferredEquityAtom);
  const m2 = get(metricsCommonEquityAtom);

  return {
    companyOwnership: get(searcherPreferredAndCommonEquityPercentAtom),
    saleProceeds: m1.saleProceeds + m2.saleProceeds,
    totalDistributions: m1.totalDistributions + m2.totalDistributions,
    totalReturn: m1.totalReturn + m2.totalReturn,
    irr: calcIrr(
      get,
      equityDollarAtom,
      atom((get) => {
        const t1 = get(equityReturnsSearcherAtom);
        const t2 = get(equityReturnsCommonEquityAtom);

        return t1.map((item, i) => {
          return {
            ...item,
            totalEquityInvestorDistributions:
              item.totalEquityInvestorDistributions +
              t2[i].totalEquityInvestorDistributions,
          };
        });
      }),
    ),
    moic: calcMoic(
      get,
      equityDollarAtom,
      atom((get) => {
        const t1 = get(equityReturnsSearcherAtom);
        const t2 = get(equityReturnsCommonEquityAtom);

        return [...t1, ...t2];
      }),
    ),
  };
});

export const metricsExternalInvestorsPreferredEquityAtom = atom((get) => {
  const totals = get(equityReturnsExternalInvestorsTotalsAtom);
  const saleProceeds = totals.proceedsFromCompanySale;

  return {
    companyOwnership: get(preferredEquityInvestorPercentAtom),
    saleProceeds,
    totalDistributions: totals.totalEquityInvestorDistributions - saleProceeds,
    totalReturn: totals.totalEquityInvestorDistributions,
    irr: calcIrr(
      get,
      equityInvestorDollarAtom,
      equityReturnsExternalInvestorsAtom,
    ),
    moic: calcMoic(
      get,
      equityInvestorDollarAtom,
      equityReturnsExternalInvestorsAtom,
    ),
  };
});

export const metricsTotalEquityAtom = atom((get) => {
  const m1 = get(metricsSearcherEquityAtom);
  const m2 = get(metricsExternalInvestorsPreferredEquityAtom);

  return {
    companyOwnership: 1,
    saleProceeds: m1.saleProceeds + m2.saleProceeds,
    totalDistributions: m1.totalDistributions + m2.totalDistributions,
    totalReturn: m1.totalReturn + m2.totalReturn,
    irr: 0,
    moic: 0,
  };
});

// table 4
// export const equityReturnsCommonEquityWithoutInvestorsAtom = atom((get) => {
//   const returns = get(equityReturnsAllInvestorsAtom);
//   const percent = toNumberOrNull(get(commonEquitySearcherPercentAtom)) || 0;

//   return returns.map((item, i) => {
//     const value = {
//       profitDistributions: getMoleculeValue(
//         get,
//         i,
//         useableFreeCashFlowMolecule,
//       ),
//       proceedsFromCompanySale:
//         getMoleculeValue(get, i, proceedsFromCompanySaleMolecule) * percent,
//     };

//     return {
//       period: item.period,
//       year: item.year,
//       ...value,
//       totalEquityInvestorDistributions: Object.values(value).reduce(
//         (acc, val) => acc + val,
//         0,
//       ),
//     };
//   });
// });

export const cashOnReturnAtom = atom((get) => {
  const returns = get(equityReturnsAllInvestorsAtom);
  const totalEquity = toNumberOrNull(get(totalEquityDollarAtom)) || 0;
  return returns.map((i) =>
    i.totalEquityInvestorDistributions <= 0
      ? 0
      : i.totalEquityInvestorDistributions / totalEquity,
  );
});

export const cashOnReturnTotalAtom = atom((get) => {
  const returns = get(cashOnReturnAtom);
  return returns.reduce((acc, val) => acc + val, 0);
});

export const cumulativePrincipalRepaymentAtom = atom((get) => {
  const returns = get(equityReturnsAllInvestorsAtom);

  let accumulator = 0;
  return returns.map((i) => {
    accumulator += i.principalRepayments;
    return accumulator;
  });
});

export const individualInvestorReturnsAtom = atom((get) => {
  const cash = get(cashOnReturnAtom);
  const startDollar =
    toNumberOrNull(get(individualInvestorReturnStartDollarAtom)) || 0;

  return cash.map((i) => {
    return i * startDollar;
  });
});

export const individualInvestorReturnsTotalAtom = atom((get) => {
  const returns = get(individualInvestorReturnsAtom);
  return returns.reduce((acc, val) => acc + val, 0);
});

export const showSecretInvestorDistributionTablesCounterAtom = atom(0);

export const totalOutstandingDebtFromCompanySaleAtom = atom((get) => {
  if (!get(companySaleIncludedAtom)) return 0;

  const sba7aPayments = get(sbaLoanAmortizationPaymentsAtom);
  const sellersPayments = get(sellersNoteAmortizationPaymentsAtom);

  let sba7aOutstandingDebt =
    sba7aPayments[sba7aPayments.length - 2].endingBalance;
  let sellersOutstandingDebt =
    sellersPayments[sellersPayments.length - 2].endingBalance;

  if (
    sba7aPayments[sba7aPayments.length - 1].totalPayment > 0 &&
    sba7aPayments[sba7aPayments.length - 1].endingBalance < 0.000001
  )
    sba7aOutstandingDebt = 0;

  if (
    sellersPayments[sellersPayments.length - 1].totalPayment > 0 &&
    sellersPayments[sellersPayments.length - 1].endingBalance < 0.000001
  )
    sellersOutstandingDebt = 0;

  return sba7aOutstandingDebt + sellersOutstandingDebt;
});
