import { Doc } from "@/components/Doc/Doc";
import { FlashOnChangeTD } from "@/components/FlashOnChange/FlashOnChange";
import { LabelExplainer } from "@/components/LabelExplainer/LabelExplainer";
import { cumulativePrincipalRepaymentAtom } from "@/financeModels/owasco/equity";
import { isEquityInvestorsIncludedAtom } from "@/financeModels/owasco/general";
import { monospaceFontFamily, textFontFamily } from "@/theme";
import { formatDollar } from "@/utils/format";
import { isNumber } from "@/utils/math";
import { Group, Table, Text } from "@mantine/core";
import { useAtomValue } from "jotai";

export const CumulativePrincipalRepaymentTable = () => {
  const cumulativePrincipalRepayment = useAtomValue(
    cumulativePrincipalRepaymentAtom,
  );

  const isInvestorIncluded = useAtomValue(isEquityInvestorsIncludedAtom);

  const items = cumulativePrincipalRepayment;

  return (
    <Table striped withColumnBorders>
      <Table.Thead
        style={{
          position: "sticky",
          top: "0",
          background: "white",
        }}
      >
        <Table.Tr>
          <Table.Th
            ta={"center"}
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <LabelExplainer
              justify="center"
              lh={1.6}
              label={
                <>
                  Cumulative <br />
                  Principal Repaid
                </>
              }
              explanation={
                <Doc
                  path={
                    "@/docs/pages/model/roi/id-cumulative-principal-repaid.mdx"
                  }
                />
              }
            />
          </Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody ff={monospaceFontFamily}>
        {items.map((item, i) => {
          const val = item === items[i - 1] && item > 0 ? null : item;

          return (
            <Table.Tr key={i}>
              <FlashOnChangeTD content={val?.toString()} ta={"right"}>
                {val === null && isInvestorIncluded && (
                  <Group w={"100%"} gap={0} justify="center">
                    <Text inherit opacity={0} w={0} c="transparent">
                      placeholder
                    </Text>
                    <Text ff={textFontFamily} fz={12} ta={"center"}>
                      Principal Returned
                    </Text>
                  </Group>
                )}
                {(val === 0 || !isInvestorIncluded) && "-"}
                {isNumber(val) && val > 0 && formatDollar(val)}
              </FlashOnChangeTD>
            </Table.Tr>
          );
        })}
      </Table.Tbody>
    </Table>
  );
};
