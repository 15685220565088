import { Doc } from "@/components/Doc/Doc";
import { FlashOnChangeTD } from "@/components/FlashOnChange/FlashOnChange";
import { LabelExplainer } from "@/components/LabelExplainer/LabelExplainer";
import {
  individualInvestorReturnsAtom,
  individualInvestorReturnsTotalAtom,
} from "@/financeModels/owasco/equity";
import { monospaceFontFamily } from "@/theme";
import { formatDollar } from "@/utils/format";
import { Table } from "@mantine/core";
import { useAtomValue } from "jotai";

export const IndividualInvestorReturnsTable = () => {
  const returns = useAtomValue(individualInvestorReturnsAtom);
  const total = useAtomValue(individualInvestorReturnsTotalAtom);

  const items = [...returns, total];

  return (
    <Table striped withColumnBorders>
      <Table.Thead
        style={{
          position: "sticky",
          top: "0",
          background: "white",
        }}
      >
        <Table.Tr>
          <Table.Th
            ta={"center"}
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <LabelExplainer
              justify="center"
              lh={1.6}
              label={
                <>
                  Individual
                  <br />
                  Investor Returns
                </>
              }
              explanation={
                <Doc
                  path={
                    "@/docs/pages/model/roi/id-individual-investor-returns.mdx"
                  }
                />
              }
            />
          </Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody ff={monospaceFontFamily}>
        {items.map((item, i) => (
          <Table.Tr key={i}>
            <FlashOnChangeTD content={item.toString()} ta={"right"}>
              {formatDollar(item)}
            </FlashOnChangeTD>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
};
