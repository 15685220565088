import { analytics, auth } from "@/firebase";
import { logEvent } from "firebase/analytics";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const usePageTracking = () => {
  const location = useLocation();
  const userInfo = auth.currentUser;

  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_path: location.pathname + location.search,
      user_id: userInfo?.uid || "anonymous",
      user_email: userInfo?.email || "anonymous",
      user_display_name: userInfo?.displayName || "anonymous",
      uid: userInfo?.uid || "anonymous",
    });
  }, [location]);
};
