import { Doc } from "@/components/Doc/Doc";
import { LabelExplainer } from "@/components/LabelExplainer/LabelExplainer";
import {
  companySaleExitMultipleAtom,
  companySaleIncludedAtom,
  companySaleYearAtom,
  purchaseMultipleAtom,
} from "@/financeModels/owasco/general";
import { projectedCompanySalePriceAtom } from "@/financeModels/owasco/projections";
import { textFontFamily } from "@/theme";
import { formatDollar } from "@/utils/format";
import { lazyReactFallback } from "@/utils/lazy";
import { isBetween, toNumberOrNull } from "@/utils/math";
import { Stack } from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { ChangeEvent } from "react";
import { MdOutlineSell } from "react-icons/md";
import { ComputedLabel } from "../Main/CellGrid/ComputedLabel";
import { ComputedResult } from "./ComputedResult";
import { InputPercent } from "./InputPercent";
import { InputToggle } from "./InputToggle";
import { InputYears } from "./InputYears";

const CompanySaleIncludedExplainer = lazyReactFallback(
  () => import("@/docs/pages/model/sidebar/CompanySaleIncluded.mdx"),
);
const CompanySaleYearExplainer = lazyReactFallback(
  () => import("@/docs/pages/model/sidebar/CompanySaleYear.mdx"),
);
const CompanySaleExitMultipleExplainer = lazyReactFallback(
  () => import("@/docs/pages/model/sidebar/CompanySaleExitMultiple.mdx"),
);

const Panel = () => {
  const [isIncluded, setIncluded] = useAtom(companySaleIncludedAtom);
  const [saleYear, setSaleYear] = useAtom(companySaleYearAtom);
  const [multiple, setMultiple] = useAtom(companySaleExitMultipleAtom);
  const purchaseMultiple = useAtomValue(purchaseMultipleAtom);
  const projectedCompanySalePrice = useAtomValue(projectedCompanySalePriceAtom);

  return (
    <Stack gap={4}>
      <InputToggle
        label={
          <LabelExplainer
            label="Will you sell the company?"
            explanation={<CompanySaleIncludedExplainer />}
          />
        }
        checked={isIncluded}
        onChange={(event) => {
          if (!multiple) {
            const amount = Number(
              (toNumberOrNull(purchaseMultiple) || 1)
                .toFixed(2)
                .replace(".00", ""),
            );

            setMultiple(amount);
          }

          setIncluded(
            (event as ChangeEvent<HTMLInputElement>).currentTarget.checked,
          );
        }}
      />
      {isIncluded && (
        <>
          <InputYears
            value={toNumberOrNull(saleYear) || ""}
            onChange={(value) => setSaleYear(toNumberOrNull(value))}
            label={
              <LabelExplainer
                label="Sell company on year"
                explanation={<CompanySaleYearExplainer />}
              />
            }
            rightSection={null}
          />

          <InputPercent
            rightSection="x"
            label={
              <LabelExplainer
                label="EBITDA Exit Multiple"
                explanation={<CompanySaleExitMultipleExplainer />}
              />
            }
            value={toNumberOrNull(multiple) || ""}
            onChange={(value) => setMultiple(toNumberOrNull(value))}
          />
          {projectedCompanySalePrice !== 0 && (
            <ComputedResult
              label={
                <LabelExplainer
                  label="Company Sale Price"
                  explanation={
                    <Doc path="@/docs/pages/model/sidebar/companySaleCompanySalePrice.mdx" />
                  }
                />
              }
              value={formatDollar(
                toNumberOrNull(projectedCompanySalePrice) || 0,
              )}
            />
          )}

          {projectedCompanySalePrice === 0 &&
            isBetween(toNumberOrNull(multiple) || 0, 0.01, 99) && (
              <ComputedResult
                label={
                  <LabelExplainer
                    label="Company Sale Price"
                    explanation={
                      <Doc path="@/docs/pages/model/sidebar/companySaleCompanySalePrice.mdx" />
                    }
                  />
                }
                value={
                  <ComputedLabel
                    tooltip={
                      "Projection year does not reach company sale year."
                    }
                    underline={true}
                    ff={textFontFamily}
                  >
                    Needs Projection
                  </ComputedLabel>
                }
              />
            )}
        </>
      )}
    </Stack>
  );
};

export const ModuleCompanySale = {
  key: "CompanySale",
  icon: <MdOutlineSell />,
  control: "Company Sale",
  panel: <Panel />,
};
