import { dealDocumentAtom } from "@/atoms/deal";
import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import { DealContact } from "@/hooks/useDeals";
import {
  ActionIcon,
  Button,
  Input,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { arrayUnion } from "firebase/firestore";
import { useAtom } from "jotai";
import { BiTrash } from "react-icons/bi";
import { IMaskInput } from "react-imask";

const columns: { key: keyof DealContact; title: string }[] = [
  {
    key: "name",
    title: "Name",
  },
  {
    key: "role",
    title: "Role",
  },
  {
    key: "email",
    title: "Email",
  },
  {
    key: "phone",
    title: "Phone",
  },
  {
    key: "organization",
    title: "Organization",
  },
];

export const Contacts = () => {
  const [[deal], setDeal] = useAtom(dealDocumentAtom);
  const contacts = deal?.contacts || [];

  const addContact = () => {
    setDeal(
      {
        // @ts-expect-error - modelIds needs arrayUniion to add
        contacts: arrayUnion({
          id: Math.random().toString(),
          name: "",
          email: "",
          phone: "",
          organization: "",
          role: "",
        }),
      },
      { merge: true },
    );
  };

  if (!deal) return null;

  return (
    <LayoutWorkstation.Panel label="Contacts">
      {contacts.length > 0 && (
        <Table.ScrollContainer minWidth={400}>
          <Table verticalSpacing="sm" horizontalSpacing={6}>
            <Table.Thead>
              <Table.Tr>
                {columns.map((column) => (
                  <Table.Th key={column.key}>{column.title}</Table.Th>
                ))}
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {contacts.map((contact) => (
                <Contact key={contact.id} contact={contact} />
              ))}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      )}
      <Button variant="outline" onClick={addContact}>
        Add Contact
      </Button>
    </LayoutWorkstation.Panel>
  );
};

const Contact = ({ contact }: { contact: DealContact }) => {
  const [[deal], setDeal] = useAtom(dealDocumentAtom);
  const contacts = deal?.contacts || [];

  const openDeleteModal = async (contact: DealContact) => {
    const name = contact.name || contact.email || "Unlabelled";

    await modals.openConfirmModal({
      title: "Delete a contact",

      children: (
        <Text size="md">
          Are you sure you want to delete the "{name}" contact? This action is
          destructive and can't be undone.
        </Text>
      ),
      labels: {
        confirm: `Delete "${name}"`,
        cancel: "Cancel",
      },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        setDeal(
          {
            contacts: contacts.filter((c) => c.id !== contact.id),
          },
          { merge: true },
        );
      },
    });
  };

  return (
    <Table.Tr key={contact.id}>
      {columns.map((column) => (
        <Table.Td key={column.key}>
          {column.key === "phone" ? (
            <Input
              w={126}
              component={IMaskInput}
              mask="(000) 000-0000"
              placeholder={column.title}
              value={contact[column.key]}
              onChange={(e) => {
                const value = e.currentTarget.value;
                setDeal(
                  {
                    contacts: contacts.map((c) =>
                      c.id === contact.id ? { ...c, [column.key]: value } : c,
                    ),
                  },
                  { merge: true },
                );
              }}
            />
          ) : (
            <TextInput
              type={column.key === "email" ? "email" : "text"}
              defaultValue={contact[column.key]}
              placeholder={column.title}
              onChange={(e) => {
                const value = e.currentTarget.value;
                setDeal(
                  {
                    contacts: contacts.map((c) =>
                      c.id === contact.id ? { ...c, [column.key]: value } : c,
                    ),
                  },
                  { merge: true },
                );
              }}
            />
          )}
        </Table.Td>
      ))}
      <Table.Td>
        <ActionIcon
          variant="transparent"
          size={"lg"}
          onClick={() => openDeleteModal(contact)}
        >
          <BiTrash />
        </ActionIcon>
      </Table.Td>
    </Table.Tr>
  );
};
