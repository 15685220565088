import { LabelExplainer } from "@/components/LabelExplainer/LabelExplainer";
import {
  legalExpensesAtom,
  otherDealExpensesAtom,
  qualityOfEarningsAtom,
  totalDealExpensesAtom,
} from "@/financeModels/owasco/general";
import { formatDollar } from "@/utils/format";
import { lazyReactFallback } from "@/utils/lazy";
import { toNumberOrNull } from "@/utils/math";
import { Stack } from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { TbReceipt2 } from "react-icons/tb";
import { ComputedResult } from "./ComputedResult";
import { InputDollar } from "./InputDollar";

const DealExpensesQualityOfEarningsExplainer = lazyReactFallback(
  () => import("@/docs/pages/model/sidebar/dealExpensesQualityOfEarnings.mdx"),
);

const DealExpensesLegalExpensesExplainer = lazyReactFallback(
  () => import("@/docs/pages/model/sidebar/dealExpensesLegalExpenses.mdx"),
);

const DealExpensesOtherDealExpensesExplainer = lazyReactFallback(
  () => import("@/docs/pages/model/sidebar/dealExpensesOtherDealExpenses.mdx"),
);

const DealExpensesTotalDealExpensesExplainer = lazyReactFallback(
  () => import("@/docs/pages/model/sidebar/dealExpensesTotalDealExpenses.mdx"),
);

export const Panel = () => {
  const [qualityOfEarnings, setQualityOfEarnings] = useAtom(
    qualityOfEarningsAtom,
  );

  const [legalExpenses, setLegalExpenses] = useAtom(legalExpensesAtom);

  const [otherDealExpenses, setOtherDealExpenses] = useAtom(
    otherDealExpensesAtom,
  );

  const totalDealExpenses = useAtomValue(totalDealExpensesAtom);

  return (
    <Stack gap={4}>
      <InputDollar
        label={
          <LabelExplainer
            label="Quality of Earnings"
            explanation={<DealExpensesQualityOfEarningsExplainer />}
          />
        }
        value={Number(qualityOfEarnings)}
        onChange={(val) => setQualityOfEarnings(toNumberOrNull(val))}
      />

      <InputDollar
        label={
          <LabelExplainer
            label="Legal Expenses"
            explanation={<DealExpensesLegalExpensesExplainer />}
          />
        }
        value={Number(legalExpenses)}
        onChange={(val) => setLegalExpenses(toNumberOrNull(val))}
      />

      <InputDollar
        label={
          <LabelExplainer
            label="Other Deal Expenses"
            explanation={<DealExpensesOtherDealExpensesExplainer />}
          />
        }
        value={Number(otherDealExpenses)}
        onChange={(val) => setOtherDealExpenses(toNumberOrNull(val))}
      />

      <ComputedResult
        label={
          <LabelExplainer
            label="Total Deal Expenses"
            explanation={<DealExpensesTotalDealExpensesExplainer />}
          />
        }
        value={formatDollar(Number(totalDealExpenses) || 0).replace(".00", "")}
      />
    </Stack>
  );
};

export const ModuleDealExpenses = {
  key: "Deal Expenses",
  icon: <TbReceipt2 />,
  control: "Deal Expenses",
  panel: <Panel />,
};
