import { Doc } from "@/components/Doc/Doc";
import { LabelExplainer } from "@/components/LabelExplainer/LabelExplainer";
import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import {
  cashRequiredByBorrowerClosingDayAtom,
  cashToSellerOnClosingDayAtom,
} from "@/financeModels/owasco/general";
import { formatDollar } from "@/utils/format";
import { Group, SimpleGrid, Stack, Text } from "@mantine/core";
import { useAtomValue } from "jotai";

export const CashRequired = () => {
  const cashRequiredByBorrowerClosingDay = useAtomValue(
    cashRequiredByBorrowerClosingDayAtom,
  );
  const cashToSellerOnClosingDay = useAtomValue(cashToSellerOnClosingDayAtom);

  return (
    <SimpleGrid cols={{ base: 1, xs: 2, md: 2 }}>
      <LayoutWorkstation.Panel>
        <Stack gap={0}>
          <Group gap={"xs"}>
            <LabelExplainer
              label={
                <Text size="xl">Cash Required by Borrower on Closing Day</Text>
              }
              explanation={
                <Doc path="@/docs/pages/model/overview/cash-required-borrower.mdx" />
              }
            />
          </Group>

          <Text size="xl" fz={30} fw={600}>
            {formatDollar(cashRequiredByBorrowerClosingDay)}
          </Text>
        </Stack>
      </LayoutWorkstation.Panel>
      <LayoutWorkstation.Panel>
        <Stack gap={0}>
          <Group gap={"xs"}>
            <LabelExplainer
              label={
                <Text size="xl">Amount Paid to Seller on Closing Day</Text>
              }
              explanation={
                <Doc path="@/docs/pages/model/overview/amount-paid-seller.mdx" />
              }
            />
          </Group>

          <Text size="xl" fz={30} fw={600}>
            {formatDollar(cashToSellerOnClosingDay)}
          </Text>
        </Stack>
      </LayoutWorkstation.Panel>
    </SimpleGrid>
  );
};
