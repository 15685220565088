import { LabelExplainer } from "@/components/LabelExplainer/LabelExplainer";
import {
  buyerAnnualCapExAtom,
  buyerDeprAndAmortScheduleAtom,
  companysTaxRateAtom,
} from "@/financeModels/owasco/general";
import { numberToPercentInput, percentInputToNumber } from "@/utils/format";
import { lazyReactFallback } from "@/utils/lazy";
import { toNumberOrNull } from "@/utils/math";
import { Stack } from "@mantine/core";
import { useAtom } from "jotai";
import { TbFileInvoice } from "react-icons/tb";
import { InputDollar } from "./InputDollar";
import { InputPercent } from "./InputPercent";
import { InputYears } from "./InputYears";

const OpAssAmortOfGoodwillExplainer = lazyReactFallback(
  () => import("@/docs/pages/model/sidebar/opAssAmortOfGoodwill.mdx"),
);
const OpAssCompanyTaxRateExplainer = lazyReactFallback(
  () => import("@/docs/pages/model/sidebar/opAssCompanyTaxRate.mdx"),
);
const OpAssMaintenanceCapExExplainer = lazyReactFallback(
  () => import("@/docs/pages/model/sidebar/opAssMaintenanceCapEx.mdx"),
);

const Panel = () => {
  const [taxRate, setTaxRate] = useAtom(companysTaxRateAtom);
  const [deprAmortYears, setDeprAmortYears] = useAtom(
    buyerDeprAndAmortScheduleAtom,
  );
  const [capEx, setCapEx] = useAtom(buyerAnnualCapExAtom);

  return (
    <Stack gap={4}>
      <InputPercent
        value={numberToPercentInput(taxRate)}
        onChange={(value) => setTaxRate(percentInputToNumber(value))}
        label={
          <LabelExplainer
            label="Company's Tax Rate"
            explanation={<OpAssCompanyTaxRateExplainer />}
          />
        }
      />
      <InputYears
        value={toNumberOrNull(deprAmortYears) || ""}
        onChange={(value) => setDeprAmortYears(toNumberOrNull(value))}
        label={
          <LabelExplainer
            label="Goodwill Amortization"
            explanation={<OpAssAmortOfGoodwillExplainer />}
          />
        }
      />
      <InputDollar
        value={toNumberOrNull(capEx) || ""}
        onChange={(value) => setCapEx(toNumberOrNull(value))}
        label={
          <LabelExplainer
            label="Maintenance CapEx"
            explanation={<OpAssMaintenanceCapExExplainer />}
          />
        }
      />
    </Stack>
  );
};

export const ModuleOperationalAssumptions = {
  key: "Operational Assumptions",
  icon: <TbFileInvoice />,
  control: "Operational Assumptions",
  panel: <Panel />,
};
