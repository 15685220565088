import { Doc } from "@/components/Doc/Doc";
import { FlashOnChangeTD } from "@/components/FlashOnChange/FlashOnChange";
import { LabelExplainer } from "@/components/LabelExplainer/LabelExplainer";
import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import {
  metricsCommonEquityAtom,
  metricsExternalInvestorsPreferredEquityAtom,
  metricsPreferredEquityAtom,
  metricsSearcherEquityAtom,
  metricsTotalEquityAtom,
} from "@/financeModels/owasco/equity";
import { monospaceFontFamily, textFontFamily } from "@/theme";
import { formatDecimalPercent, formatDollar } from "@/utils/format";
import { toNumberOrNull } from "@/utils/math";
import { ActionIcon, Group, Table } from "@mantine/core";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { MdSubdirectoryArrowRight } from "react-icons/md";
import { RxTriangleDown, RxTriangleRight } from "react-icons/rx";

export const ReturnMetricsTable = () => {
  const [showSubItems, setShowSubItems] = useState(false);
  const metricsPreferredEquity = useAtomValue(metricsPreferredEquityAtom);
  const metricsCommonEquity = useAtomValue(metricsCommonEquityAtom);
  const metricsSearcherEquity = useAtomValue(metricsSearcherEquityAtom);
  const metricsExternalInvestorsPreferredEquity = useAtomValue(
    metricsExternalInvestorsPreferredEquityAtom,
  );
  const metricsTotalEquity = useAtomValue(metricsTotalEquityAtom);

  const investors = [
    {
      key: "searcher",
      title: (
        <Group align="center" wrap="nowrap">
          <LabelExplainer
            label={"Searcher (Preferred & Common Equity)"}
            explanation={
              <Doc path={"@/docs/pages/model/roi/id-searcher.mdx"} />
            }
          />
          <ActionIcon
            variant="light"
            color="accent"
            onClick={() => setShowSubItems((v) => !v)}
            size={"sm"}
          >
            {!showSubItems ? (
              <RxTriangleRight size={16} />
            ) : (
              <RxTriangleDown size={16} />
            )}
          </ActionIcon>
        </Group>
      ),
      ...metricsSearcherEquity,
    },

    ...(!showSubItems
      ? []
      : [
          {
            key: "preferredEquity",
            title: (
              <LabelExplainer
                label={
                  <>
                    <MdSubdirectoryArrowRight style={{ margin: "0 10px" }} />
                    Preferred Equity
                  </>
                }
                explanation={
                  <Doc
                    path={"@/docs/pages/model/roi/id-preferred-equity.mdx"}
                  />
                }
              />
            ),
            ...metricsPreferredEquity,
          },
          {
            key: "commonEquity",
            title: (
              <LabelExplainer
                label={
                  <>
                    <MdSubdirectoryArrowRight style={{ margin: "0 10px" }} />
                    Common Equity
                  </>
                }
                explanation={
                  <Doc path={"@/docs/pages/model/roi/id-common-equity.mdx"} />
                }
              />
            ),
            ...metricsCommonEquity,
          },
        ]),

    {
      key: "externalInvestorsPreferredEquity",
      title: (
        <LabelExplainer
          label={"External Investors (Preferred Equity)"}
          explanation={
            <Doc path={"@/docs/pages/model/roi/id-external-investors.mdx"} />
          }
        />
      ),
      ...metricsExternalInvestorsPreferredEquity,
    },

    {
      key: "totalEquity",
      title: "Total Equity",
      ...metricsTotalEquity,
    },
  ] as const;

  return (
    <>
      <LayoutWorkstation.Panel p={0} flex={1} miw={1000}>
        <Table
          striped
          withColumnBorders
          withTableBorder
          style={{ whiteSpace: "nowrap" }}
          w={"100%"}
        >
          <Table.Thead h={60}>
            <Table.Tr>
              <Table.Th>Equity Investors</Table.Th>
              <Table.Th ta={"center"}>
                <LabelExplainer
                  lh={1.6}
                  justify="center"
                  label={
                    <>
                      Company
                      <br />
                      Ownership
                    </>
                  }
                  explanation={
                    <Doc path="@/docs/pages/model/roi/cap-table-company-ownership.mdx" />
                  }
                />
              </Table.Th>
              <Table.Th ta={"center"}>
                <LabelExplainer
                  lh={1.6}
                  justify="center"
                  label={
                    <>
                      Total
                      <br />
                      Distributions
                    </>
                  }
                  explanation={
                    <Doc path="@/docs/pages/model/roi/cap-table-total-distributions.mdx" />
                  }
                />
              </Table.Th>
              <Table.Th ta={"center"}>
                <LabelExplainer
                  lh={1.6}
                  justify="center"
                  label={
                    <>
                      Net Sales
                      <br />
                      Proceeds
                    </>
                  }
                  explanation={
                    <Doc path="@/docs/pages/model/roi/cap-table-sales-proceeds.mdx" />
                  }
                />
              </Table.Th>
              <Table.Th ta={"center"}>
                <LabelExplainer
                  lh={1.6}
                  justify="center"
                  label={
                    <>
                      Total
                      <br />
                      Return
                    </>
                  }
                  explanation={
                    <Doc path="@/docs/pages/model/roi/cap-table-total-return.mdx" />
                  }
                />
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody ff={monospaceFontFamily}>
            {investors.map((investor) => (
              <Table.Tr
                key={investor.key}
                ta={"right"}
                bg={investor.title === "Total Equity" ? "infomatic.0" : ""}
                fw={investor.title === "Total Equity" ? "bold" : ""}
              >
                <Table.Td ta={"left"} ff={textFontFamily}>
                  {investor.title}
                </Table.Td>
                <FlashOnChangeTD
                  content={investor.companyOwnership?.toString()}
                >
                  {formatDecimalPercent(investor.companyOwnership)}
                </FlashOnChangeTD>
                <FlashOnChangeTD
                  content={investor.totalDistributions.toString()}
                >
                  {formatDollar(investor.totalDistributions)}
                </FlashOnChangeTD>
                <FlashOnChangeTD content={investor.saleProceeds.toString()}>
                  {formatDollar(investor.saleProceeds)}
                </FlashOnChangeTD>
                <FlashOnChangeTD content={investor.totalReturn.toString()}>
                  {formatDollar(investor.totalReturn)}
                </FlashOnChangeTD>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </LayoutWorkstation.Panel>

      <LayoutWorkstation.Panel p={0}>
        <Table
          striped
          withColumnBorders
          withTableBorder
          style={{ whiteSpace: "nowrap" }}
        >
          <Table.Thead h={60}>
            <Table.Tr>
              <Table.Th ta={"center"}>
                <LabelExplainer
                  justify="center"
                  label={"IRR"}
                  explanation={<Doc path="@/docs/pages/model/roi/irr.mdx" />}
                />
              </Table.Th>
              <Table.Th>
                <LabelExplainer
                  justify="center"
                  label={"MOIC"}
                  explanation={<Doc path="@/docs/pages/model/roi/moic.mdx" />}
                />
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody ff={monospaceFontFamily}>
            {investors.slice(0, -1).map((investor) => (
              <Table.Tr
                key={investor.key}
                ta={"right"}
                bg={investor.title === "Total Equity" ? "infomatic.0" : ""}
              >
                <FlashOnChangeTD content={investor.irr?.toString()}>
                  {formatDecimalPercent(investor.irr)}
                </FlashOnChangeTD>
                <FlashOnChangeTD content={investor.moic?.toString()}>
                  {(toNumberOrNull(investor.moic) || 0).toFixed(2)}x
                </FlashOnChangeTD>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </LayoutWorkstation.Panel>
    </>
  );
};
