import { BigDollarInput } from "@/components/BigDollarInput/BigDollarInput";
import { Doc } from "@/components/Doc/Doc";
import { LabelExplainer } from "@/components/LabelExplainer/LabelExplainer";
import { individualInvestorReturnStartDollarAtom } from "@/financeModels/owasco/general";
import { toNumberOrNull } from "@/utils/math";
import { Card, Group } from "@mantine/core";
import { useAtom } from "jotai";

export const IndividualInvestorReturnInput = () => {
  const [dollar, setDollar] = useAtom(individualInvestorReturnStartDollarAtom);

  return (
    <Group justify="flex-end" pos={"absolute"} top={-100}>
      <Card
        bg="blue.1"
        p={4}
        style={{
          borderBottom: "none",
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0 0 0px 1px, inset rgba(0, 0, 0, 0.1) 0 0 1px 1px",
        }}
      >
        <BigDollarInput
          right={0}
          top={0}
          value={toNumberOrNull(dollar) || 0}
          onChange={(value) => setDollar(value || 0)}
          size="sm"
          w={125}
          label={
            <span
              style={{
                fontSize: 13,
                position: "relative",
                left: 4,
                top: 3,
                marginRight: 3,
                transform: "translate(4px 3px)",
              }}
            >
              <LabelExplainer
                inline
                label={
                  <>
                    Individual Investor <br />
                    Projected Return
                  </>
                }
                explanation={
                  <Doc path="@/docs/pages/model/roi/individual-investor-projected-return.mdx" />
                }
              />
            </span>
          }
        />
      </Card>
    </Group>
  );
};
