import { Dial } from "@/components/Dial/Dial";
import {
  annualDebtSbaLoanAtom,
  annualDebtSellersNoteAtom,
  annualDebtServiceAtom,
  dscrAtom,
  purchaseMultipleAtom,
} from "@/financeModels/liteAtoms";
import { useLiteModelToFull } from "@/hooks/useLiteModelToFull";
import { isBetween, toNumberOrNull } from "@/utils/math";
import {
  Anchor,
  Badge,
  Button,
  Group,
  NumberFormatter,
  Paper,
  Stack,
  Table,
  Text,
  Tooltip,
} from "@mantine/core";
import { useAtom } from "jotai";
import { format } from "mathjs";
import { CgArrowRight } from "react-icons/cg";
import { RiQuestionFill } from "react-icons/ri";

export const Stats = () => {
  const [purchaseMultiple] = useAtom(purchaseMultipleAtom);
  const [annualDebtService] = useAtom(annualDebtServiceAtom);
  const [annualDebtSellersNote] = useAtom(annualDebtSellersNoteAtom);
  const [annualDebtSbaLoan] = useAtom(annualDebtSbaLoanAtom);
  const [dscr] = useAtom(dscrAtom);

  const styles = {
    bg: "infomatic.0",
    style: {
      borderLeft: "4px solid var(--mantine-color-infomatic-2)",
      borderTop: "1px solid var(--mantine-color-infomatic-2)",
      borderBottom: "1px solid var(--mantine-color-infomatic-2)",
      borderRight: "1px solid var(--mantine-color-infomatic-2)",
    },
    radius: 0,
  };

  let dscrColor = "infomatic.9";
  if (isBetween(dscr, 0.01, 1.25)) dscrColor = "pink.8";
  if (isBetween(dscr, 1.25, 1.5)) dscrColor = "yellow.8";
  if (isBetween(dscr, 1.5, 200)) dscrColor = "green.8";

  return (
    <Stack style={{ position: "sticky", top: 24 }}>
      <Paper pos={"relative"} {...styles} px={"xl"} pb={26} pt={38}>
        <Badge
          c={"var(--mantine-color-infomatic-8"}
          bg={"var(--mantine-color-infomatic-2)"}
          pos={"absolute"}
          top={0}
          left={-3}
          size="lg"
          radius={0}
        >
          Debt Structure
        </Badge>
        <Stack gap={"lg"}>
          <Table
            w={"100%"}
            withRowBorders={false}
            fz={"lg"}
            style={{
              borderBottom: "1px solid var(--mantine-color-infomatic-2)",
            }}
          >
            <Table.Tbody>
              <Table.Tr>
                <Table.Td></Table.Td>
                <Table.Td px={0}>
                  <Text>SBA 7(a) Loan</Text>
                </Table.Td>
                <Table.Td style={{ textAlign: "right", fontWeight: "bold" }}>
                  <NumberFormatter
                    thousandSeparator
                    prefix="($"
                    suffix=")"
                    decimalScale={0}
                    value={Math.abs(toNumberOrNull(annualDebtSbaLoan) ?? 0)}
                  />
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td w={26} fw={"bold"} p={0}>
                  +
                </Table.Td>
                <Table.Td px={0}>
                  <Text>Seller’s Note</Text>
                </Table.Td>
                <Table.Td style={{ textAlign: "right", fontWeight: "bold" }}>
                  <NumberFormatter
                    thousandSeparator
                    prefix="($"
                    suffix=")"
                    decimalScale={0}
                    value={Math.abs(toNumberOrNull(annualDebtSellersNote) ?? 0)}
                  />
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>

          <Stack justify="center" align="center" gap={"sm"}>
            <Stack justify="center" align="center" gap={4}>
              <Text size="xl" c="red" fz={40} fw={"bold"}>
                (
                <NumberFormatter
                  thousandSeparator
                  prefix="$"
                  decimalScale={0}
                  value={Math.abs(toNumberOrNull(annualDebtService) ?? 0)}
                />
                )
              </Text>
              <Text size="xl" fw={"bold"}>
                Annual Debt Service
              </Text>
            </Stack>
          </Stack>
        </Stack>
      </Paper>

      <Paper pos={"relative"} {...styles} px={14} pb={16} pt={35}>
        <Badge
          c={"var(--mantine-color-infomatic-8"}
          bg={"var(--mantine-color-infomatic-2)"}
          pos={"absolute"}
          top={0}
          left={-3}
          size="lg"
          radius={0}
        >
          Deal Metrics
        </Badge>

        {isBetween(dscr, 0.01, 1.5) && (
          <Tooltip
            w={320}
            position="top"
            multiline
            withArrow
            label="Some lenders will accept a 1.25x DSCR, but most require a minimum of 1.50x DSCR. Adjust your Finance Structure to increase your DSCR"
            events={{ hover: true, focus: true, touch: true }}
          >
            <Anchor
              w="100%"
              ta={"center"}
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                zIndex1: 1,
                //transform: "translateY(100%)",
              }}
              c="pink.5"
              bg={"infomatic.9"}
              fw={600}
              underline="always"
              fz={14}
              py={2}
            >
              Low DSCR Alert
              <RiQuestionFill
                style={{ position: "relative", top: 3 }}
                size={16}
              />
            </Anchor>
          </Tooltip>
        )}

        <Group justify="center" align="center" gap={0}>
          <Dial
            w={"50%"}
            bg={"infomatic.1"}
            color={"infomatic.9"}
            value={Math.min(
              100,
              Math.max(0, ((toNumberOrNull(purchaseMultiple) ?? 0) / 10) * 100),
            )}
            title={
              isBetween(purchaseMultiple, 0.1, 15)
                ? format(purchaseMultiple, {
                    notation: "fixed",
                    precision: 2,
                  }) + "x"
                : "---"
            }
            subtitle="Purchase Multiple"
          />

          <Dial
            w={"50%"}
            bg={"infomatic.1"}
            color={dscrColor}
            value={Math.min(
              100,
              Math.max(0, ((toNumberOrNull(dscr) ?? 0) / 3) * 100),
            )}
            title={
              isBetween(dscr, 0.1, 10)
                ? format(dscr, {
                    notation: "fixed",
                    precision: 2,
                  }) + "x"
                : "---"
            }
            subtitle="DSCR"
          />
        </Group>
      </Paper>
      <CashFlowCallout />
    </Stack>
  );
};

const CashFlowCallout = () => {
  const { createModel, loading } = useLiteModelToFull();

  return (
    <Stack gap={5} px={"xs"} ta={"center"} display={"none"}>
      <Text>Have the company’s financials or CIM?</Text>

      <Button
        size="xl"
        loading={loading}
        mt={"sm"}
        onClick={createModel}
        radius={999}
        rightSection={<CgArrowRight size={24} />}
      >
        Customize your model
      </Button>
    </Stack>
  );
};
