import { LabelExplainer } from "@/components/LabelExplainer/LabelExplainer";
import { sbaLoanAmortizationScheduleAtom } from "@/financeModels/owasco/amortization";
import { sbaLoanInterestRateAtom } from "@/financeModels/owasco/general";
import { numberToPercentInput, percentInputToNumber } from "@/utils/format";
import { lazyReactFallback } from "@/utils/lazy";
import { Stack } from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { TbBuildingBank } from "react-icons/tb";
import { ComputedResult } from "./ComputedResult";
import { DealFees } from "./DealFees";
import { InputPercent } from "./InputPercent";

const Sba7aLoanInterestRateExplainer = lazyReactFallback(
  () => import("@/docs/pages/model/sidebar/sba7aLoanInterestRate.mdx"),
);
const Sba7aLoanAmortScheduleExplainer = lazyReactFallback(
  () => import("@/docs/pages/model/sidebar/sba7aLoanAmortSchedule.mdx"),
);

// const Sba7aLoanLoanStartDateExplainer = lazyReactFallback(
//   () => import("@/docs/pages/model/sidebar/sba7aLoanLoanStartDate.mdx"),
// );

const Panel = () => {
  const [interestRate, setInterestRate] = useAtom(sbaLoanInterestRateAtom);
  //const [loanStartDate, setLoanStartDate] = useAtom(sbaLoanStartDateAtom);
  const amortSchedule = useAtomValue(sbaLoanAmortizationScheduleAtom);
  return (
    <Stack gap={4}>
      <InputPercent
        label={
          <LabelExplainer
            label="Interest Rate"
            explanation={<Sba7aLoanInterestRateExplainer />}
          />
        }
        value={numberToPercentInput(interestRate)}
        onChange={(value) => setInterestRate(percentInputToNumber(value))}
      />

      <ComputedResult
        label={
          <LabelExplainer
            label="Amortization Schedule"
            explanation={<Sba7aLoanAmortScheduleExplainer />}
          />
        }
        value={amortSchedule.toFixed(2).replace(".00", "") + " years"}
        variant="default"
        my={6}
      />
      {/* <InputDate
        label={
          <LabelExplainer
            label="Loan Start Date"
            explanation={<Sba7aLoanLoanStartDateExplainer />}
          />
        }
        value={loanStartDate.toDate()}
        onChange={(e) => setLoanStartDate(Timestamp.fromDate(e as Date))}
      /> */}
      <DealFees />
    </Stack>
  );
};

export const ModuleSBA7aLoan = {
  key: "SBA 7(a) Loan",
  icon: <TbBuildingBank />,
  control: "SBA 7(a) Loan",
  panel: <Panel />,
};
