import { firestore } from "@/firebase";
import { collection } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";

export const PageListSignups = () => {
  const [docs, loading, error] = useCollectionData(
    collection(firestore, "newsletterSignups"),
  );

  const sortedDocs = docs?.sort((a, b) => {
    return a.createdAt.toMillis() - b.createdAt.toMillis();
  });

  return (
    <div>
      <h1>List</h1>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {sortedDocs && (
        <ul>
          {sortedDocs.map((doc) => (
            <li key={doc.id} style={{ borderBottom: "1px solid gray" }}>
              <span style={{ width: 300, display: "inline-block" }}>
                {doc.email}
              </span>
              {doc.createdAt.toDate().toLocaleString()}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
