import { Doc } from "@/components/Doc/Doc";
import { FlashOnChangeTR } from "@/components/FlashOnChange/FlashOnChange";
import { LabelExplainer } from "@/components/LabelExplainer/LabelExplainer";
import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import {
  usesAtom,
  usesTotalCostAtom,
} from "@/financeModels/owasco/sourcesAndUses";
import { monospaceFontFamily } from "@/theme";
import { formatDecimalPercent, formatDollar } from "@/utils/format";
import { Table } from "@mantine/core";
import { useAtomValue } from "jotai";

export const Uses = () => {
  const items = useAtomValue(usesAtom);
  const totalProjectCost = useAtomValue(usesTotalCostAtom);

  return (
    <LayoutWorkstation.Panel p={0}>
      <Table striped>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Uses</Table.Th>
            <Table.Th ta={"right"} pr={30}>
              Amount
            </Table.Th>
            <Table.Th w={"10%"}></Table.Th>
            <Table.Th ta={"right"} w={100}>
              % of Total
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {items.map((item) => (
            <FlashOnChangeTR
              key={item[0]}
              content={item?.[1]?.toString() + totalProjectCost?.toString()}
            >
              <Table.Td>
                <LabelExplainer
                  label={item[0]}
                  explanation={<Doc path={item[2]} />}
                />
              </Table.Td>
              <Table.Td ta={"right"} w={160} ff={monospaceFontFamily}>
                {formatDollar(Number(item[1]))}
              </Table.Td>
              <Table.Td></Table.Td>
              <Table.Td ff={monospaceFontFamily}>
                {formatDecimalPercent(
                  Number(item[1]) / Number(totalProjectCost),
                )}
              </Table.Td>
            </FlashOnChangeTR>
          ))}

          <Table.Tr fw={700}>
            <Table.Td>
              {" "}
              <LabelExplainer
                label={"Total Project Cost"}
                explanation={
                  <Doc
                    path={"@/docs/pages/model/overview/uses-total-cost.mdx"}
                  />
                }
              />
            </Table.Td>
            <Table.Td ta={"right"} w={160} ff={monospaceFontFamily}>
              {formatDollar(totalProjectCost)}
            </Table.Td>
            <Table.Td></Table.Td>
            <Table.Td fw={700} ff={monospaceFontFamily}>
              100%
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </LayoutWorkstation.Panel>
  );
};
