import { agGridRefAtom } from "@/atoms/dealTracker/agGrid";
import { ActionIcon, Group, Popover, TextInput, Tooltip } from "@mantine/core";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { TbFilterCog } from "react-icons/tb";
import { ColumnToggle } from "./ColumnToggle";

export const Toolbar = () => {
  const agGridRef = useAtomValue(agGridRefAtom);
  const [value, setValue] = useState("");

  useEffect(() => {
    if (!agGridRef?.current) return;

    agGridRef!.current!.api.setGridOption("quickFilterText", value);
  }, [agGridRef, value]);

  return (
    <Group pl={"xs"} gap={"xs"}>
      <TextInput
        w={180}
        leftSectionPointerEvents="none"
        leftSection={<FaSearch />}
        placeholder="Search..."
        size="xs"
        onChange={(e) => setValue(e.currentTarget.value)}
        value={value}
        rightSection={
          value ? (
            <ActionIcon
              size={18}
              color="gray"
              variant="transparent"
              onClick={() => setValue("")}
            >
              <IoClose />
            </ActionIcon>
          ) : null
        }
      />
      <Popover width={420} position="bottom" withArrow shadow="md">
        <Popover.Target>
          <Tooltip label="Add/Remove Columns" withArrow>
            <ActionIcon color="gray.4" h={30} variant="outline">
              <TbFilterCog size={20} color="var(--mantine-color-gray-5)" />
            </ActionIcon>
          </Tooltip>
        </Popover.Target>
        <Popover.Dropdown>
          <ColumnToggle />
        </Popover.Dropdown>
      </Popover>
    </Group>
  );
};
