import { LabelExplainer } from "@/components/LabelExplainer/LabelExplainer";
import {
  isLenderLineOfCreditInErrorAtom,
  lenderLineOfCreditDayOneDrawdownAtom,
  lenderLineOfCreditErrorMessageAtom,
  lenderLineOfCreditTotalAmountAtom,
} from "@/financeModels/owasco/general";
import { lazyReactFallback } from "@/utils/lazy";
import { toNumberOrNull } from "@/utils/math";
import { Alert, Stack } from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { TbCashBanknote } from "react-icons/tb";
import { InputDollar } from "./InputDollar";

const LineOfCreditTotalAmountExplainer = lazyReactFallback(
  () => import("@/docs/pages/model/sidebar/lineOfCreditTotalAmount.mdx"),
);
const LineOfCreditDayOneDrawdownExplainer = lazyReactFallback(
  () => import("@/docs/pages/model/sidebar/lineOfCreditDayOneDrawdown.mdx"),
);

const Panel = () => {
  const [total, setTotal] = useAtom(lenderLineOfCreditTotalAmountAtom);
  const [dayOneDrawdown, setDayOneDrawdown] = useAtom(
    lenderLineOfCreditDayOneDrawdownAtom,
  );
  const errorMessage = useAtomValue(lenderLineOfCreditErrorMessageAtom);

  return (
    <Stack gap={4}>
      <InputDollar
        label={
          <LabelExplainer
            label="Total Amount"
            explanation={<LineOfCreditTotalAmountExplainer />}
          />
        }
        value={toNumberOrNull(total) || ""}
        onChange={(value) => setTotal(toNumberOrNull(value))}
      />
      <InputDollar
        label={
          <LabelExplainer
            label="Day One Drawdown"
            explanation={<LineOfCreditDayOneDrawdownExplainer />}
          />
        }
        value={toNumberOrNull(dayOneDrawdown) || ""}
        onChange={(value) => setDayOneDrawdown(toNumberOrNull(value))}
      />

      {errorMessage && (
        <Alert variant="light" color="red" ta={"center"}>
          {errorMessage}
        </Alert>
      )}
    </Stack>
  );
};

export const ModuleLenderLineOfCredit = {
  key: "Lender Line Of Credit",
  icon: <TbCashBanknote />,
  control: "Lender Line Of Credit",
  panel: <Panel />,
  errorAtom: isLenderLineOfCreditInErrorAtom,
};
