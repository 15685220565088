import {
  Box,
  Button,
  Container,
  Grid,
  Group,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";

import { Route } from "@/paths";
import { textFontFamily } from "@/theme";
import { Link } from "react-router-dom";
import { NumberInputCashFlow } from "./NumberInputCashFlow";
import { NumberInputPurchasePrice } from "./NumberInputPurchasePrice";

export const Jumbotron = ({ scrollTo }: { scrollTo: () => void }) => {
  return (
    <Box bg="styledBackgrounds.5">
      <Container
        size={"xl"}
        pt={{ sm: "18vh", base: 50 }}
        pb={{ sm: "26vh", base: 140 }}
        px={{ base: 18, sm: 40, xl: 0 }}
      >
        <Grid gutter={{ base: 12, sm: 60 }} align="center">
          <Grid.Col
            span={{ base: 12, md: 7 }}
            style={{ container: " jumbotron-text / inline-size" }}
          >
            <Title
              fw={400}
              c="white"
              fz={{ base: 28, sm: 28, md: 36, lg: 46, xl: 56 }}
              mb={"sm"}
              style={{ letterSpacing: 1.2 }}
            >
              <Text
                inherit
                display={{
                  base: "block",
                  sm: "inline",
                  xs: "block",
                  md: "block",
                }}
              >
                A Financial Modeling
                <Text
                  component="span"
                  ff={textFontFamily}
                  inherit
                  fz={{
                    base: 28 - 10,
                    sm: 25 - 10,
                    md: 30 - 10,
                    lg: 40 - 10,
                    xl: 50 - 10,
                  }}
                >
                  {" "}
                  &{" "}
                </Text>
                CRM <br />
                Platform for Search Funds
              </Text>
            </Title>
            <Text c="gray.4" fz={{ base: 16, sm: 20, md: 22 }} mb={40}>
              Manage your pipeline, run cash flow models, and project investor
              returns with ETA IQ —{" "}
              <strong>the CRM purpose built for SMB acquisitions. </strong>
            </Text>
            <Group align="center">
              <Button
                component={Link}
                to={Route.Signup}
                size="lg"
                display={"none"}
              >
                Get Started — it's free
              </Button>

              <Button component={Link} to={Route.Waitlist} size="lg">
                Join our Waitlist
              </Button>
            </Group>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 5 }}>
            <Stack align="center">
              <Text
                c={{ base: "transparent", sm: "gray.3" }}
                mb={"sm"}
                ta={"center"}
              >
                Gauge your deal fundamentals in seconds:
              </Text>
              <Paper radius={0} p="xl" withBorder maw={500} w={"100%"}>
                <form
                  onSubmit={(e) => {
                    // stop form submission
                    e.preventDefault();
                    scrollTo();
                  }}
                >
                  <Stack>
                    <NumberInputPurchasePrice />
                    <NumberInputCashFlow />
                    <Button fullWidth size="lg" type="submit" justify="center">
                      Analyze Your Deal
                    </Button>
                  </Stack>
                </form>
              </Paper>
            </Stack>
          </Grid.Col>
        </Grid>
      </Container>
    </Box>
  );
};
